import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import ForgetPassword from './modules/auth/ForgetPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import ResetPassword from './modules/auth/ResetPassword';
import SetPassword from './modules/auth/SetPassword';
import { PROFILE } from './modules/auth/graphql/Queries';
import Categories from './modules/categories/Categories';
import CategoryDetails from './modules/categories/CategoryDetails';
import InventoryOut from './modules/categories/components/inventory/InventoryOut';
import ProductDetails from './modules/categories/components/products/ProductDetails';
import ContractList from './modules/contractor/ContractList';
import Contractor from './modules/contractor/Contractor';
import ContractorDetails from './modules/contractor/ContractorDetails';
import Customer from './modules/customer/Customer';
import CustomerDetails from './modules/customer/CustomerDetails';
import MemberDetails from './modules/members/MemberDetails';
import Members from './modules/members/Members';
import AddMoreItem from './modules/ordersBilling/AddMoreItem';
import OrdersBilling from './modules/ordersBilling/OrdersBilling';
import OrdersDetail from './modules/ordersBilling/OrdersDetail';
import Profile from './modules/profile/Profile';

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES?.FORGET_PASSWORD,
      element: <PublicRoute />,
      children: [
        { path: ROUTES?.FORGET_PASSWORD, element: <ForgetPassword /> },
      ],
    },
    {
      path: ROUTES?.RESET,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.RESET, element: <ResetPassword /> }],
    },
    {
      path: ROUTES?.SET_PASSWORD,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.SET_PASSWORD, element: <SetPassword /> }],
    },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
    {
      path: ROUTES?.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> }],
    },
  ];

  const CATEGORIES_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            { path: '', element: <Navigate to={ROUTES.CATEGORIES} /> },
            {
              path: ROUTES?.CATEGORIES,
              children: [
                { path: ROUTES?.CATEGORIES, element: <Categories /> },
                {
                  path: ':sessionId/inventory-out',
                  element: <InventoryOut />,
                },
                {
                  path: ':id',
                  element: <CategoryDetails />,
                  children: [
                    {
                      path: ':tab',
                      element: <CategoryDetails />,
                    },
                  ],
                },
                {
                  path: ':id/:tab/:productId',
                  element: <ProductDetails />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const MEMBERS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.MEMBERS,
              children: [
                { path: ROUTES?.MEMBERS, element: <Members /> },
                { path: ':id', element: <MemberDetails /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const CUSTOMER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.CUSTOMER,
              children: [
                { path: ROUTES?.CUSTOMER, element: <Customer /> },
                { path: ':id', element: <CustomerDetails /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PROFILE,
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ];

  const ORDERS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.ORDER_AND_BILLING,
              children: [
                { path: ROUTES?.ORDER_AND_BILLING, element: <OrdersBilling /> },
                {
                  path: ':id',
                  element: <OrdersDetail />,
                },
                {
                  path: ':id/add-more',
                  element: <AddMoreItem />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const CONTRACTOR_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.CONTRACTOR,
              children: [
                {
                  path: ROUTES?.CONTRACTOR,
                  element: <Contractor />,
                },
                {
                  path: ':id',
                  element: <ContractorDetails />,
                  // children: [
                  //   {
                  //     path: ':idd',
                  //     element: <ContractList />,
                  //   },
                  // ],
                },
                {
                  path: ':id/:contractId',
                  element: <ContractList />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
            {
              path: '404',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...CATEGORIES_MODULES,
    ...USER_MODULES,
    ...OTHER_MODULES,
    ...MEMBERS_MODULES,
    ...CUSTOMER_MODULES,
    ...ORDERS_MODULES,
    ...CONTRACTOR_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const path = history?.location?.pathname;
  const idToken = getToken();

  const [getCurrentUser] = useLazyQuery(PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.profile);
      setLoading(false);
    },
    onError: () => {
      history?.push(ROUTES?.LOGOUT);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (path === ROUTES?.LOGOUT || idToken) {
      getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
