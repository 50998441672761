import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      message
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $where: UniqueWhereInput!
    $input: UpdateCustomerInput!
  ) {
    updateCustomer(where: $where, input: $input) {
      message
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($where: UniqueWhereInput!) {
    deleteCustomer(where: $where) {
      message
    }
  }
`;
