import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Breadcrumb, Button, Tabs, Tooltip } from 'antd';
import { capitalize, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EditIcon, OrderHistoryYellow } from '../../assets/svg';
import {
  CONTRACT_PRODUCT_COLOR,
  MODULES,
  ROUTES,
} from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import Portal from '../../components/Portal';
import AddContractModal from './AddContractModal';
import './contractor.less';
import { REGENERATE_CONTRACT_PDF } from './graphql/Mutations';
import { CONTRACT_ITEM } from './graphql/Queries';

const initialUserFilter = {
  filters: { skip: 0, limit: 10 },
  sort: { sortOn: 'createdAt', sortBy: 'DESC' },
};

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const ContractList = () => {
  const { contractId } = useParams();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const {
    state: { contractorData, mainData },
  } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [activeTab, setActiveTab] = useState('out');
  const [userSessionFilter, setUserSessionFilter] = useState({
    ...initialUserFilter,
    filters: {
      ...initialUserFilter?.filters,
      contractId,
    },
  });
  const navigate = useNavigate();
  const [regenerateContractPdf] = useMutation(REGENERATE_CONTRACT_PDF, {
    onError: () => {},
  });
  const [
    getContracts,
    { data: contractData, loading: contractLoading },
  ] = useLazyQuery(CONTRACT_ITEM, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.contractItems?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    setUserSessionFilter({
      ...userSessionFilter,
      filters: {
        ...userSessionFilter?.filters,
        contractId,
      },
    });
    getContracts({
      variables: {
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          contractId,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setUserSessionFilter(initialUserFilter);
    setPaginationProp(initialPaginationValue);
    getContracts({
      variables: {
        ...initialUserFilter,
        filters: { ...initialUserFilter?.filters, contractId },
      },
    });
  };
  const items = [
    {
      key: 'out',
      label: 'Out',
      children: (
        <Alert
          className="mb-10"
          message="Please note that you can edit or delete the contractor within next 24 hours. And you won’t be able to regenerate the PDF after 36 hours."
          type="warning"
          showIcon
          icon={<OrderHistoryYellow height={22} width={22} />}
        />
      ),
    },
    {
      key: 'in',
      label: 'In',
    },
  ];
  const columns = [
    {
      title: '#',
      key: '#',
      align: 'center',
      width: '10%',
      render: (record, _, index) => (
        <div>{userSessionFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
      align: 'center',
    },
    {
      title: 'Packaging',
      dataIndex: 'packaging',
      key: 'packaging',
      align: 'center',
      render: (record) => <>{capitalize(record)}</>,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      align: 'center',
      render: (record) => <>{CONTRACT_PRODUCT_COLOR[record]}</>,
    },
    ...(activeTab === 'out'
      ? [
          {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
          },
        ]
      : []),
    {
      title: 'No of Bindi',
      dataIndex: 'noOfBindi',
      key: 'noOfBindi',
      align: 'center',
    },
    ...(activeTab === 'out'
      ? [
          {
            title: 'No of Pattas',
            dataIndex: 'noOfPattas',
            key: 'noOfPattas',
            align: 'center',
          },
        ]
      : []),
    ...(activeTab === 'in'
      ? [
          {
            title: 'Unit',
            dataIndex: 'inUnit',
            key: 'inUnit',
            align: 'center',
            render: (record) => (
              <>
                {record
                  ? `${record?.gross}G ${record?.dozen}Dz ${record?.pieces}P`
                  : '-'}
              </>
            ),
          },
          {
            title: 'Wastage',
            dataIndex: 'wastagePercentage',
            key: 'wastagePercentage',
            align: 'center',
            render: (record) => (
              <div className={record <= 2 ? 'text-green' : 'text-red'}>
                {record ? `${record}%` : '-'}
              </div>
            ),
          },
          {
            title: 'Price /Gross',
            dataIndex: 'pricePerGross',
            key: 'pricePerGross',
            align: 'center',
          },
          {
            title: 'Total Amt',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            align: 'center',
          },
        ]
      : []),
    {
      align: 'center',
      render: (text, record) => (
        <>
          {((contractorData?.isEditable && activeTab === 'out') ||
            (!contractorData?.isEditable && activeTab === 'in')) && (
            <Tooltip title="Edit Contractor">
              <EditIcon
                className="mr-10 pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRecord(record);
                  setIsModalOpen(true);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserSessionFilter({
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      getContracts({
        variables: {
          ...userSessionFilter,
          filters: {
            ...userSessionFilter?.filters,
            skip,
            limit: pagination.pageSize,
            contractId,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setUserSessionFilter({
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      getContracts({
        variables: {
          ...userSessionFilter,
          filters: {
            ...userSessionFilter?.filters,
            skip,
            limit: pagination.pageSize,
            contractId,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  return (
    <div className="member-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.CONTRACTOR}</h1>,
              onClick: () => navigate(ROUTES.CONTRACTOR),
              className: 'pointer',
            },
            {
              title: <h1 className="m-0 font-600">{mainData?.code}</h1>,
              onClick: () => navigate(`${ROUTES.CONTRACTOR}/${mainData?.id}`),
              className: 'pointer',
            },
            {
              title: <h2 className="m-0 font-600">{contractorData?.code}</h2>,
            },
          ]}
        />
        <Button
          type="primary"
          className="font-600 submit-btn "
          // loading={shipmentLoading}
          onClick={() => {
            regenerateContractPdf({
              variables: {
                where: {
                  id: contractorData?.id,
                },
                input: {
                  type: upperCase(activeTab),
                },
              },
            });
          }}
        >
          REGENERATE PDF
        </Button>
      </Portal>

      <div className="d-flex justify-between align-center mb-24">
        <h2 className="m-0">CONTRACT DETAILS</h2>
      </div>
      <Tabs
        defaultActiveKey={activeTab}
        items={items}
        onChange={setActiveTab}
      />
      <TableComponent
        loadingData={contractLoading}
        columns={columns}
        data={contractData?.contractItems?.contractItems}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
      {isModalOpen && (
        <AddContractModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          activeTab={activeTab}
        />
      )}
    </div>
  );
};

export default ContractList;
