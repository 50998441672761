import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Logo } from '../assets/svg';
import { APP_VERSION, BREAKPOINTS, ROUTES } from '../common/constants';
import './App.css';
import AppHeader from './components/header/AppHeader';
import Sidebar from './components/sidebar/Sidebar';

const { Content, Sider } = Layout;
const App = () => {
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Layout hasSider>
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Sider
        theme="light"
        className={isActive ? 'close' : null}
        breakpoint="md"
      >
        <Link id="logo" to={ROUTES?.MAIN} title={`Version: v${APP_VERSION}`}>
          <Logo />
        </Link>
        <Sidebar />
      </Sider>
      <Layout className="site-layout">
        <AppHeader>
          <div id="header-wrapper" className="header-wrapper">
            {/* <UserProfile /> */}
          </div>
        </AppHeader>
        <Content className="wrapper">
          <Outlet />
        </Content>
        {/* <Footer>
          <div className="text-center">Porwal © {new Date().getFullYear()}</div>
        </Footer> */}
      </Layout>
    </Layout>
  );
};

export default App;
