import { gql } from '@apollo/client';

export const ADD_CONTRACTOR = gql`
  mutation CreateContractor($input: CreateContractorInput!) {
    createContractor(input: $input) {
      message
    }
  }
`;

export const CREATE_CONTRACT = gql`
  mutation CreateContract($input: CreateContractInput!) {
    createContract(input: $input) {
      message
    }
  }
`;

export const UPDATE_CONTRACTOR = gql`
  mutation UpdateContractor(
    $where: UniqueWhereInput!
    $input: UpdateContractorInput!
  ) {
    updateContractor(where: $where, input: $input) {
      message
    }
  }
`;

export const UPDATE_CONTRACT_ITEM = gql`
  mutation UpdateContractItem(
    $where: UniqueWhereInput!
    $input: ContractItemInput!
  ) {
    updateContractItem(where: $where, input: $input) {
      message
    }
  }
`;

export const DELETE_CONTRACTOR = gql`
  mutation DeleteContractor($where: UniqueWhereInput!) {
    deleteContractor(where: $where) {
      message
    }
  }
`;

export const DELETE_CONTRACT = gql`
  mutation DeleteContract($where: UniqueWhereInput!) {
    deleteContract(where: $where) {
      message
    }
  }
`;

export const ADD_CONTACT_ITEM_IN_UNIT = gql`
  mutation AddContractItemInUnit(
    $where: UniqueWhereInput!
    $input: AddContractItemInUnitInput!
  ) {
    addContractItemInUnit(where: $where, input: $input) {
      message
    }
  }
`;

export const ADD_CONTRACT_ITEM_PRICE = gql`
  mutation AddContractItemPrice(
    $where: UniqueWhereInput!
    $input: AddContractItemPriceInput!
  ) {
    addContractItemPrice(where: $where, input: $input) {
      message
    }
  }
`;

export const REGENERATE_CONTRACT_PDF = gql`
  mutation RegenerateContractPdf(
    $where: UniqueWhereInput!
    $input: RegenerateContractPdfInput!
  ) {
    regenerateContractPdf(where: $where, input: $input) {
      message
    }
  }
`;
