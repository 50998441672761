import { useLazyQuery } from '@apollo/client';
import { Col, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MODULES,
  ROUTES,
  STATUS_COLORS,
  defaultDateFormat,
} from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import { FETCH_ORDERS } from './graphql/Queries';

const initialOrderFilter = {
  filters: { skip: 0, limit: 10 },
  sort: { sortOn: 'createdAt', sortBy: 'DESC' },
};

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const OrdersBilling = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [ordersFilter, setOrdersFilter] = useState(initialOrderFilter);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});

  const [fetchOrders, { loading, data }] = useLazyQuery(FETCH_ORDERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.ordersAdmin?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    fetchOrders({
      variables: {
        ...ordersFilter,
        filters: { ...ordersFilter?.filters, customerId: state?.customerId },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, _, index) => (
        <div>{ordersFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Order ID',
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Customer Email',
      dataIndex: ['customer', 'email'],
      key: 'customer_email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => (
        <Tag className={`products-tags ${STATUS_COLORS[status]}`}>
          {capitalize(status)}
        </Tag>
      ),
    },
    {
      title: 'Placed By',
      dataIndex: ['orderBy', 'email'],
      key: 'orderBy_email',
    },
    {
      title: 'Placed On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (date) => dayjs(date).format(defaultDateFormat),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);

    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setOrdersFilter({
        ...ordersFilter,
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchOrders({
        variables: {
          ...ordersFilter,
          filters: {
            ...ordersFilter?.filters,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setOrdersFilter({
        ...ordersFilter,
        filters: { ...ordersFilter?.filters, skip, limit: pagination.pageSize },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      fetchOrders({
        variables: {
          ...ordersFilter,
          filters: {
            ...ordersFilter?.filters,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  const onSearchChange = async (value) => {
    setOrdersFilter({
      ...ordersFilter,
      filters: {
        ...ordersFilter?.filters,
        skip: 0,
        search: value,
      },
    });
    fetchOrders({
      variables: {
        ...ordersFilter,
        filters: {
          ...ordersFilter?.filters,
          skip: 0,
          search: value,
        },
      },
    });
  };

  return (
    <div className="categories-module">
      <Portal portalId="header-wrapper">
        <h1 className="m-0 font-600">{MODULES.ORDER_AND_BILLING}</h1>
      </Portal>
      <Row className="mb-24" justify="space-between">
        <Col>
          <SearchComponent
            id="search-container-id"
            name="orders"
            getData={onSearchChange}
            className="category-search search-component"
          />
        </Col>
      </Row>
      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.ordersAdmin?.orders}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.ORDER_AND_BILLING}/${record?.id}`);
          },
        })}
        rowClassName="pointer"
        scroll={{ y: 472 }}
      />
    </div>
  );
};

export default OrdersBilling;
