import { useMutation } from '@apollo/client';
import { Button, Col, Modal, Row } from 'antd';
import { upperCase } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CHANGE_STATUS } from '../../common/constants';
import { UPDATE_ORDER_STATUS } from './graphql/Mutations';
import { FETCH_ORDER } from './graphql/Queries';

const ChangeStatusModal = ({ isModalOpen, setIsModalOpen, status }) => {
  const { id } = useParams();
  const [updateOrderStatus, { loading }] = useMutation(UPDATE_ORDER_STATUS, {
    refetchQueries: [
      {
        query: FETCH_ORDER,
        variables: {
          where: { id },
        },
        fetchPolicy: 'network-only',
      },
    ],
    onError() {},
  });

  return (
    <div>
      <Modal
        centered
        closable={false}
        title={<h3 className="m-0 mb-10">{upperCase(`Change Status`)}</h3>}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={576}
      >
        <p className="mb-16 mt-10">
          Are you sure you want to change the status?
        </p>
        <Row gutter={10}>
          <Col span={12}>
            <Button
              disabled={loading}
              onClick={() => {
                setIsModalOpen(false);
              }}
              className="full-width font-600 submit-btn"
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              className="full-width font-600 submit-btn"
              loading={loading}
              onClick={() => {
                updateOrderStatus({
                  variables: {
                    where: {
                      id,
                    },
                    input: {
                      status: CHANGE_STATUS[status],
                    },
                  },
                }).then((res) => {
                  if (res?.data?.updateOrderStatus) setIsModalOpen(false);
                });
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ChangeStatusModal;
