import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Breadcrumb,
  Button,
  Descriptions,
  Divider,
  Image,
  Spin,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { capitalize, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PdfIcon, PrintIcon, ViewIcon } from '../../../../assets/svg';
import {
  MODULES,
  ROUTES,
  defaultDateFormat,
} from '../../../../common/constants';
import { handlePdfDownload } from '../../../../common/utils';
import TableComponent from '../../../../components/CommonTable';
import Portal from '../../../../components/Portal';
import { GET_PRODUCT, GET_PRODUCT_VARIATIONS } from '../../graphql/Queries';
import PrintBarcodeModal from './PrintBarcodeModal';
import ViewBarcodeModal from './ViewBarcodeModal';

const ProductDetails = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const initialFilter = {
    skip: 0,
    limit: 10,
    productId,
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const [productVariationFilter, setProductVariationFilter] = useState(
    initialFilter,
  );
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [openBarcodeModal, setOpenBarcodeModal] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [barCode, setBarCode] = useState(null);
  const [sku, setSku] = useState(null);
  const [color, setColor] = useState(null);

  const { loading, data } = useQuery(GET_PRODUCT, {
    variables: {
      where: {
        id: productId,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [
    fetchProductVariations,
    { data: productVariationsData, loading: productVariationLoading },
  ] = useLazyQuery(GET_PRODUCT_VARIATIONS, {
    variables: {
      filters: { ...productVariationFilter },
      sort: {
        sortBy: 'DESC',
        sortOn: 'code',
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.productVariations?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    if (productId) {
      fetchProductVariations();
    }
  }, [productId]);

  const items = [
    {
      key: 'productId',
      label: 'Product ID',
      children: data?.product?.code,
    },
    {
      key: 'productName',
      label: 'Product Name',
      children: data?.product?.name,
    },
    {
      key: 'productRange',
      label: 'Product Range',
      children: data?.product?.range || '-',
    },
    {
      key: 'categoryName',
      label: 'Category Name',
      children: data?.product?.category?.name,
    },
    {
      key: 'addedOn',
      label: 'Added On',
      children: dayjs(data?.product?.createdAt).format(defaultDateFormat),
    },
    {
      key: 'status',
      label: 'Status',
      span: 4,
      className: 'status-div',
      children: capitalize(data?.product?.status),
    },
    {
      key: 'attachments',
      label: 'Attachments',
      className: 'other-type',
      children:
        data?.product?.attachmentType === 'IMAGE' ? (
          <div className="attachments-div">
            <Image.PreviewGroup>
              {map(data?.product?.attachments, (attachment) => (
                <div className="product-image">
                  <Image
                    className="mr-8"
                    src={attachment?.url}
                    alt="img"
                    key={attachment?.key}
                    height={80}
                    width={80}
                  />
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
        ) : (
          <div className="d-flex">
            {map(data?.product?.attachments, (attachment) => (
              <div className="d-flex align-center">
                <div className="pdf-detail-div">
                  <PdfIcon
                    alt="pdf"
                    key={attachment?.key}
                    height={40}
                    width={40}
                  />
                </div>
                <div className="ml-12">
                  <p className="m-0">{attachment?.key?.split('/')?.[1]}</p>
                  <Button
                    type="primary"
                    onClick={() =>
                      handlePdfDownload(
                        attachment?.url,
                        attachment?.key?.split('/')?.[1],
                      )
                    }
                  >
                    Download
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setProductVariationFilter({
        ...productVariationFilter,
        filters: {
          ...productVariationFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchProductVariations({
        variables: {
          ...productVariationFilter,
          filters: {
            ...productVariationFilter?.filters,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setProductVariationFilter({
        ...productVariationFilter,
        filters: {
          ...productVariationFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      fetchProductVariations({
        variables: {
          ...productVariationFilter,
          filters: {
            ...productVariationFilter?.filters,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, _, index) => (
        <div>{productVariationFilter?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Variation ID',
      dataIndex: 'code',
      key: 'code',
      align: 'start',
    },
    {
      title: 'Variations',
      dataIndex: 'details',
      key: 'variations',
      align: 'start',
      render: (record) => (
        <div>
          {map(record, (list) => (
            <div>
              <span className="variation-heading">{list?.name} : </span>
              {list?.value?.name}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'start',
    },
    {
      title: 'Barcode',
      key: 'barCode',
      align: 'center',
      render: (record) => (
        <>
          <Tooltip title="View Barcode">
            <ViewIcon
              className="mr-10 pointer"
              onClick={() => {
                setOpenBarcodeModal(true);
                setBarCode(record?.barCode);
              }}
            />
          </Tooltip>
          <Tooltip title="Print Barcode">
            <PrintIcon
              className="pointer"
              onClick={() => {
                const colorName =
                  record?.details?.find((item) => item?.key === 'COLOR')?.value
                    ?.name ?? '';
                setOpenPrintModal(true);
                setBarCode(record?.barCode);
                setSku(record?.code);
                setColor(colorName);
              }}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'start',
      render: (createdAt) => dayjs(createdAt).format(defaultDateFormat),
    },
  ];

  return (
    <div className="member-details">
      {openBarcodeModal && (
        <ViewBarcodeModal
          openModal={openBarcodeModal}
          setOpenModal={setOpenBarcodeModal}
          barCode={barCode}
        />
      )}
      {openPrintModal && (
        <PrintBarcodeModal
          openModal={openPrintModal}
          setOpenModal={setOpenPrintModal}
          barCode={barCode}
          sku={sku}
          color={color}
          productData={data?.product}
        />
      )}
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.CATEGORIES}</h1>,
              onClick: () => navigate(ROUTES.CATEGORIES),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">
                  {data?.product?.category?.name ?? ''}
                </h2>
              ),
              onClick: () =>
                navigate(
                  `${ROUTES.CATEGORIES}/${data?.product?.category?.id}/products`,
                ),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">{data?.product?.name ?? ''}</h2>
              ),
            },
          ]}
        />
      </Portal>
      <Spin spinning={loading}>
        <Descriptions
          layout="vertical"
          items={items}
          colon={false}
          column={4}
        />
      </Spin>
      <Divider dashed className="divider-dashed" />
      <TableComponent
        tableClassName="mt-28"
        loadingData={productVariationLoading}
        columns={columns}
        data={productVariationsData?.productVariations?.productVariations}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
    </div>
  );
};
export default ProductDetails;
