import { useLazyQuery, useMutation } from '@apollo/client';
import { Breadcrumb, Col, Divider, Input, Row, message } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import ScannerInput from './Scanner';
import { ADD_ORDER_ITEM } from './graphql/Mutations';
import { FETCH_ORDER } from './graphql/Queries';
import './orders.less';

const { Search } = Input;

const AddMoreItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [orderItemsData, setOrderItemsData] = useState([]);

  const [fetchOrder, { loading, data, refetch }] = useLazyQuery(FETCH_ORDER, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.orderAdmin) {
        setOrderItemsData(res?.orderAdmin?.items);
      }
    },
    onError() {},
  });

  useEffect(() => {
    if (id) fetchOrder();
  }, [id]);

  const [addOrderItem] = useMutation(ADD_ORDER_ITEM, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {
      refetch();
    },
  });

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, _, index) => <div>{index + 1}</div>,
    },
    {
      title: 'Product ID',
      dataIndex: ['product', 'code'],
      key: 'product_code',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Variation',
      dataIndex: 'details',
      key: 'variation',
      width: '25%',
      align: 'center',
      render: (details) => (
        <>
          {map(details, (productItem, index) => (
            <p className="m-0" key={index}>
              {productItem?.name}: {productItem?.value?.name}
            </p>
          ))}
        </>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'center',
      key: 'quantity',
      width: '20%',
    },
  ];

  const onScanComplete = (barCode, { source = '' }) => {
    if (source !== 'clear')
      if (barCode) {
        addOrderItem({
          variables: {
            input: {
              barCode,
              orderId: id,
            },
          },
        });
      } else {
        message.destroy();
        message.error('Please scan or enter the barcode.');
      }
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="orders-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex breadcrumb-title"
          items={[
            {
              title: (
                <h1 className="m-0 font-600">{MODULES.ORDER_AND_BILLING}</h1>
              ),
              onClick: () => navigate(ROUTES.ORDER_AND_BILLING),
              className: 'pointer',
            },
            {
              title: (
                <h1 className="m-0 font-600">
                  {data?.orderAdmin?.orderNo ?? ''}
                </h1>
              ),
              onClick: () =>
                navigate(`${ROUTES.ORDER_AND_BILLING}/${data?.orderAdmin?.id}`),
              className: 'pointer',
            },
            {
              title: <h2 className="m-0 font-600">Order Detail</h2>,
            },
          ]}
        />
      </Portal>
      <Row gutter={10} className="mb-20">
        <Col span={12}>
          <p className="top-label">Product Variation Code</p>
          <Search
            placeholder="Enter product variation code"
            allowClear
            enterButton="Add"
            size="large"
            onSearch={(e, event, info) => onScanComplete(e, info)}
          />
        </Col>
        <Col span={12}>
          <p className="top-label">Scanned Product Code</p>
          <ScannerInput onScanComplete={onScanComplete} />
        </Col>
      </Row>
      <Divider dashed className="divider-dashed" />
      <div className="mt-20">
        <div className="d-flex justify-between align-center mb-24">
          <h2 className="m-0">ORDER DETAILS</h2>
        </div>
        <TableComponent
          loadingData={loading}
          columns={columns}
          data={orderItemsData}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </div>
  );
};

export default AddMoreItem;
