import { gql } from '@apollo/client';

export const FETCH_ORDERS = gql`
  query OrdersAdmin($filters: OrdersFilterInput, $sort: OrdersSortInput) {
    ordersAdmin(filters: $filters, sort: $sort) {
      count
      orders {
        id
        customer {
          id
          email
        }
        orderBy {
          id
          email
        }
        status
        invoicePdfUrl {
          key
          url
        }
        carrierProvider
        shipmentId
        orderNo
        createdAt
        items {
          id
          pricePerUnit
          quantity
          product {
            id
            code
          }
          details {
            name
            key
            value {
              key
              name
            }
          }
        }
      }
    }
  }
`;

export const FETCH_ORDER = gql`
  query OrderAdmin($where: UniqueWhereInput!) {
    orderAdmin(where: $where) {
      id
      customer {
        id
        email
      }
      orderBy {
        id
        email
      }
      status
      invoicePdfUrl {
        key
        url
      }
      carrierProvider
      shipmentId
      orderNo
      createdAt
      items {
        id
        product {
          id
          code
        }
        details {
          name
          key
          value {
            key
            name
          }
        }
        quantity
        pricePerUnit
      }
    }
  }
`;
