import { useLazyQuery, useQuery } from '@apollo/client';
import { Breadcrumb, Descriptions, Radio, Spin } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MODULES,
  ROLE_KEYS,
  ROUTES,
  defaultDateFormat,
} from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import Portal from '../../components/Portal';
import { GET_USER, USER_SESSION } from './graphql/Queries';
import './members.less';

const initialUserFilter = {
  filters: { skip: 0, limit: 10 },
  sort: { sortOn: 'createdAt', sortBy: 'DESC' },
};

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const MemberDetails = () => {
  const { id } = useParams();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [userSessionFilter, setUserSessionFilter] = useState(initialUserFilter);
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_USER, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [
    userSessions,
    { data: sessionData, loading: sessionLoading },
  ] = useLazyQuery(USER_SESSION, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.userSessions?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    setUserSessionFilter({
      ...userSessionFilter,
      filters: {
        ...userSessionFilter?.filters,
        userId: id,
      },
    });
    userSessions({
      variables: {
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          userId: id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const items = [
    {
      key: '1',
      label: 'First Name',
      children: data?.user?.firstName,
    },
    {
      key: '2',
      label: 'Last Name',
      children: data?.user?.lastName,
    },
    {
      key: '3',
      label: 'Email',
      children: data?.user?.email,
    },
    {
      key: '4',
      label: 'Added On',
      children: dayjs(data?.user?.createdAt).format(defaultDateFormat),
    },
    {
      key: '5',
      label: 'Access Level',
      className: 'other-type',
      children: (
        <Radio.Group value={data?.user?.role}>
          <Radio value={ROLE_KEYS.ADMIN}>{capitalize(ROLE_KEYS.ADMIN)}</Radio>
          <Radio value={ROLE_KEYS.STAFF}>{capitalize(ROLE_KEYS.STAFF)}</Radio>
        </Radio.Group>
      ),
    },
  ];
  const columns = [
    {
      title: '#',
      key: '#',
      render: (record, _, index) => (
        <div>{userSessionFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Browser',
      dataIndex: 'browser',
      key: 'browser',
    },
    {
      title: 'Os',
      dataIndex: 'os',
      key: 'os',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },

    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (date) => dayjs(date).format(defaultDateFormat),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserSessionFilter({
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      userSessions({
        variables: {
          ...userSessionFilter,
          filters: {
            ...userSessionFilter?.filters,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setUserSessionFilter({
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      userSessions({
        variables: {
          ...userSessionFilter,
          filters: {
            ...userSessionFilter?.filters,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };
  return (
    <div className="member-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.MEMBERS}</h1>,
              onClick: () => navigate(ROUTES.MEMBERS),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">
                  {data?.user?.firstName} {data?.user?.lastName}
                </h2>
              ),
            },
          ]}
        />
      </Portal>

      <Spin spinning={loading}>
        <Descriptions
          layout="vertical"
          items={items}
          colon={false}
          column={4}
        />
      </Spin>
      <TableComponent
        loadingData={sessionLoading}
        columns={columns}
        data={sessionData?.userSessions?.userSessions}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
      />
    </div>
  );
};

export default MemberDetails;
