import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

const ScannerInput = ({ onScanComplete, onScanError }) => {
  const [inputValue, setInputValue] = useState('');
  const [scannerData, setScannerData] = useState({
    stringWriting: '',
    firstCharTime: 0,
    lastCharTime: 0,
    scanButtonCounter: 0,
  });

  const resetScannerData = () => {
    setScannerData({
      stringWriting: '',
      firstCharTime: 0,
      lastCharTime: 0,
      scanButtonCounter: 0,
    });
  };

  const handleKeyDown = (e) => {
    // Example implementation, customize based on your scanner's key code
    if (e.keyCode === 13) {
      // Assuming Enter key is the endChar
      e.preventDefault();
      if (
        Date.now() - scannerData.firstCharTime <
        scannerData.stringWriting.length * 40
      ) {
        onScanComplete(scannerData.stringWriting, { source: '' });
        setInputValue(scannerData.stringWriting);
        resetScannerData();
      } else {
        onScanError(scannerData.stringWriting);
      }
    }
  };

  const handleKeyPress = (e) => {
    e.preventDefault();
    const char = String.fromCharCode(e.which);
    const currentTime = Date.now();
    setScannerData((prevState) => ({
      ...prevState,
      stringWriting: prevState.stringWriting + char,
      firstCharTime: prevState.firstCharTime || currentTime,
      lastCharTime: currentTime,
    }));
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const input = document.getElementById('scan-input');
    input.addEventListener('keydown', handleKeyDown);
    input.addEventListener('keypress', handleKeyPress);

    return () => {
      input.removeEventListener('keydown', handleKeyDown);
      input.removeEventListener('keypress', handleKeyPress);
    };
  }, [scannerData]);

  return (
    <Input
      type="text"
      id="scan-input"
      value={inputValue}
      onChange={(e) => {
        setInputValue(e?.target?.value);
      }}
      autoFocus
      readOnly
      size="large"
      placeholder="Code will be displayed once scanned"
    />
  );
};

export default ScannerInput;
