import { useQuery } from '@apollo/client';
import { Breadcrumb, Tabs } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import CategoryInventories from './components/inventory/CategoryInventories';
import CategoryProducts from './components/products/CategoryProducts';
import CategoryVariations from './components/variations/CategoryVariations';
import { GET_CATEGORY } from './graphql/Queries';

const CategoryDetails = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const { loading, data } = useQuery(GET_CATEGORY, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const items = [
    {
      key: 'variations',
      label: 'Variations',
      children: <CategoryVariations id={id} />,
    },
    {
      key: 'products',
      label: 'Products',
      children: <CategoryProducts id={id} />,
    },
    {
      key: 'inventory',
      label: 'Inventory',
      children: <CategoryInventories id={id} />,
    },
  ];

  if (!map(items, 'key')?.includes(tab)) {
    return <Navigate to="/404" replace />;
  }

  if (loading) return <LoaderComponent />;

  return (
    <div>
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.CATEGORIES}</h1>,
              onClick: () => navigate(ROUTES.CATEGORIES),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">{data?.category?.name ?? ''}</h2>
              ),
            },
          ]}
        />
      </Portal>
      <div>
        <Tabs
          className="category-tabs"
          defaultActiveKey="variations"
          items={items}
          activeKey={tab}
          onChange={(key) => {
            navigate(`${ROUTES.CATEGORIES}/${id}/${key}`);
          }}
          destroyInactiveTabPane
          tabBarExtraContent={<div id="add-category-details" />}
        />
      </div>
    </div>
  );
};

export default CategoryDetails;
