import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { isEmpty, lowerCase, map, startCase, upperCase } from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Delete } from '../../assets/svg';
import {
  CONTRACT_PRODUCT_PACKAGING_TYPE,
  contractColorOptions,
} from '../../common/constants';
import { normalizeNegativeNumber } from '../../common/utils';
import {
  ADD_CONTACT_ITEM_IN_UNIT,
  ADD_CONTRACT_ITEM_PRICE,
  CREATE_CONTRACT,
  UPDATE_CONTRACT_ITEM,
} from './graphql/Mutations';

function AddContractModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  setSelectedRecord,
  selectedRecord,
  activeTab = 'out',
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const { id } = useParams();
  const [createUpdateContract] = useMutation(CREATE_CONTRACT, {
    onError: () => {
      setLoading(false);
    },
  });

  const [updateContractItem] = useMutation(UPDATE_CONTRACT_ITEM, {
    onError: () => {
      setLoading(false);
    },
    onCompleted: () => {
      setIsModalOpen(false);
      refetch();
    },
  });
  const [addContractItemInUnit] = useMutation(ADD_CONTACT_ITEM_IN_UNIT, {
    onError: () => {
      setLoading(false);
    },
    onCompleted: () => {
      setIsModalOpen(false);
      refetch();
    },
  });
  const [addContractItemPrice] = useMutation(ADD_CONTRACT_ITEM_PRICE, {
    onError: () => {
      setLoading(false);
    },
    onCompleted: () => {
      setIsModalOpen(false);
      refetch();
    },
  });

  const onFinish = async (values) => {
    setLoading(true);
    if (selectedRecord && activeTab === 'out') {
      updateContractItem({
        variables: {
          where: { id: selectedRecord?.id },
          input: {
            productName: values?.contractItems?.[0]?.productName,
            quantity: Number(values?.contractItems?.[0]?.quantity),
            noOfBindi: Number(values?.contractItems?.[0]?.noOfBindi),
            noOfPattas: Number(values?.contractItems?.[0]?.noOfPattas),
            packaging: values?.contractItems?.[0]?.packaging,
            color: values?.contractItems?.[0]?.color,
          },
        },
      });
    } else if (selectedRecord && activeTab === 'in') {
      if (selectedRecord?.pricePerGross > 0) {
        addContractItemPrice({
          variables: {
            where: { id: selectedRecord?.id },
            input: {
              price: Number(values?.contractItems?.[0]?.pricePerGross),
            },
          },
        });
      } else {
        addContractItemInUnit({
          variables: {
            where: { id: selectedRecord?.id },
            input: {
              dozen: Number(values?.contractItems?.[0]?.inUnit?.dozen),
              gross: Number(values?.contractItems?.[0]?.inUnit?.gross),
              pieces: Number(values?.contractItems?.[0]?.inUnit?.pieces),
            },
          },
        }).then((res) => {
          if (res && values?.contractItems?.[0]?.pricePerGross) {
            addContractItemPrice({
              variables: {
                where: { id: selectedRecord?.id },
                input: {
                  price: Number(values?.contractItems?.[0]?.pricePerGross),
                },
              },
            });
          }
        });
      }
    } else {
      createUpdateContract({
        variables: {
          input: {
            ...values,
            contractItems: map(values?.contractItems, (item) => ({
              ...item,
              quantity: Number(item?.quantity),
              noOfBindi: Number(item?.noOfBindi),
              noOfPattas: Number(item?.noOfPattas),
            })),
            contractorId: id,
          },
        },
      }).then((res) => {
        if (res?.data) {
          setLoading(false);
          setIsModalOpen(false);
          setSelectedRecord(null);
          refetch();
        }
      });
    }
  };

  return (
    <div id="add-edit-categories-modal">
      <Modal
        centered
        title={
          <h3 className="m-0 mb-10">
            {upperCase(
              `${selectedRecord ? 'Edit Contract Item' : 'Add Contract'}`,
            )}
          </h3>
        }
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={650}
      >
        <Form
          form={form}
          name="basic"
          className="category-forms mt-16"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          initialValues={
            selectedRecord
              ? { contractItems: [selectedRecord] }
              : { contractItems: [{}] }
          }
          onValuesChange={(changedValues) => {
            if (!isEmpty(changedValues)) {
              setIsValuesChanged(true);
            }
          }}
        >
          <Form.List name={['contractItems']} label="Variations">
            {(fields, { add, remove }, { errors }) => (
              <div className="contract-form">
                {fields?.map((field, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="form-div">
                    <Row justify="space-between" align="middle">
                      <Col className="title">Contract Item</Col>
                      <Col className="delete-icon">
                        {fields?.length > 1 ? (
                          <Col span={2}>
                            <Delete
                              width={20}
                              height={20}
                              onClick={() => {
                                remove(field?.name);
                              }}
                            />
                          </Col>
                        ) : null}
                      </Col>
                    </Row>
                    <Row gutter={10} align="middle">
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label="Product Name"
                          name={[field?.name, 'productName']}
                          key={[field?.key, 'productName']}
                          rules={[
                            { required: true, message: 'Enter product name!' },
                          ]}
                        >
                          <Input
                            disabled={activeTab === 'in'}
                            placeholder="Enter Product Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label="Quantity"
                          name={[field?.name, 'quantity']}
                          key={[field?.key, 'quantity']}
                          rules={[
                            { required: true, message: 'Enter quantity!' },
                          ]}
                          normalize={normalizeNegativeNumber}
                        >
                          <Input
                            disabled={activeTab === 'in'}
                            type="number"
                            placeholder="Enter Quantity"
                            className="number-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {activeTab !== 'in' && (
                      <Row gutter={10} align="middle">
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Number Of Bindi"
                            name={[field?.name, 'noOfBindi']}
                            key={[field?.key, 'noOfBindi']}
                            rules={[
                              { required: true, message: 'Enter number!' },
                            ]}
                            normalize={normalizeNegativeNumber}
                          >
                            <Input
                              type="number"
                              className="number-input"
                              placeholder="Enter number of bindi"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Number Of Pattas"
                            name={[field?.name, 'noOfPattas']}
                            key={[field?.key, 'noOfPattas']}
                            rules={[
                              { required: true, message: 'Enter number!' },
                            ]}
                            normalize={normalizeNegativeNumber}
                          >
                            <Input
                              type="number"
                              className="number-input"
                              placeholder="Enter number of pattas"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={10} key={field?.key} align="middle">
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label="Packaging"
                          name={[field?.name, 'packaging']}
                          key={[field?.key, 'packaging']}
                          rules={[
                            { required: true, message: 'Please select!' },
                          ]}
                        >
                          <Select
                            placeholder="Select Packaging"
                            disabled={activeTab === 'in'}
                          >
                            {map(
                              Object.keys(CONTRACT_PRODUCT_PACKAGING_TYPE),
                              (data) => (
                                <Select.Option key={data} value={data}>
                                  {startCase(lowerCase(data))}
                                </Select.Option>
                              ),
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label="Color"
                          name={[field?.name, 'color']}
                          key={[field?.key, 'color']}
                          rules={[
                            { required: true, message: 'Please select!' },
                          ]}
                        >
                          <Select
                            placeholder="Select Color"
                            disabled={activeTab === 'in'}
                          >
                            {contractColorOptions.map(({ key, value }) => (
                              <Select.Option key={key} value={key}>
                                {value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {selectedRecord && activeTab === 'in' && (
                      <>
                        <Form.Item
                          {...field}
                          label="In unit"
                          name={[field?.name, 'inUnit']}
                          key={[field?.key, 'inUnit']}
                          rules={[{ required: true, message: 'Enter unit!' }]}
                        >
                          <Row gutter={10}>
                            <Col span={4}>
                              <div className="unit-title">Gross</div>
                            </Col>
                            <Col span={20}>
                              <Form.Item
                                className="mb-10"
                                name={[field?.name, 'inUnit', 'gross']}
                                key={[field?.key, 'inUnit', 'gross']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input data!',
                                  },
                                ]}
                              >
                                <Input
                                  disabled={selectedRecord?.pricePerGross > 0}
                                  placeholder="Enter data here"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10}>
                            <Col span={4}>
                              <div className="unit-title">Dozen</div>
                            </Col>
                            <Col span={20}>
                              <Form.Item
                                className="mb-10"
                                name={[field?.name, 'inUnit', 'dozen']}
                                key={[field?.key, 'inUnit', 'dozen']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input data!',
                                  },
                                ]}
                              >
                                <Input
                                  disabled={selectedRecord?.pricePerGross > 0}
                                  placeholder="Enter data here"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={10}>
                            <Col span={4}>
                              <div className="unit-title">Pieces</div>
                            </Col>
                            <Col span={20}>
                              <Form.Item
                                className="mb-10"
                                name={[field?.name, 'inUnit', 'pieces']}
                                key={[field?.key, 'inUnit', 'pieces']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input data!',
                                  },
                                ]}
                              >
                                <Input
                                  disabled={selectedRecord?.pricePerGross > 0}
                                  placeholder="Enter data here"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                        {selectedRecord?.inUnit && (
                          <Form.Item
                            label="Price Per Gross"
                            name={[field?.name, 'pricePerGross']}
                            key={[field?.key, 'pricePerGross']}
                            rules={[
                              {
                                required: true,
                                message: 'Please input data!',
                              },
                            ]}
                          >
                            <Input placeholder="Enter data here" />
                          </Form.Item>
                        )}
                      </>
                    )}
                  </div>
                ))}
                {!selectedRecord && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      className="full-width"
                      icon={<PlusOutlined />}
                    >
                      Add Another Product
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                )}
              </div>
            )}
          </Form.List>
          <Button
            type="primary"
            htmlType="submit"
            className="full-width font-600 submit-btn"
            disabled={loading || !isValuesChanged}
            loading={loading}
          >
            {selectedRecord ? `SAVE` : `SAVE & GENERATE PDF`}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default AddContractModal;
