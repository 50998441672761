import { Image, Modal } from 'antd';
import { map, upperCase } from 'lodash';
import React from 'react';

const ProductImageModal = ({ open, setOpen, images }) => (
  <Modal
    open={open}
    onCancel={() => setOpen(false)}
    centered
    title={<h2 className="m-0 mb-10">{upperCase('Attachments')}</h2>}
    footer={null}
  >
    <div className="d-flex flex-wrap p-12">
      <Image.PreviewGroup>
        {map(images, (image) => (
          <div className="mr-12 mb-12">
            <Image
              src={image?.url}
              alt="img"
              key={image?.key}
              height={80}
              width={80}
            />
          </div>
        ))}
      </Image.PreviewGroup>
    </div>
  </Modal>
);

export default ProductImageModal;
