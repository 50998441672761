import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CreateUpdateCategoryInput!) {
    createCategory(input: $input) {
      category {
        createdAt
        id
        image {
          key
          url
        }
        name
        slug
        updatedAt
      }
      message
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $where: UniqueWhereInput!
    $input: CreateUpdateCategoryInput!
  ) {
    updateCategory(where: $where, input: $input) {
      message
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($where: UniqueWhereInput!) {
    deleteCategory(where: $where) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      message
      success
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
      data {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_CATEGORY_VARIATION = gql`
  mutation CreateCategoryVariation(
    $where: UniqueWhereInput!
    $input: CreateCategoryVariationInput!
  ) {
    createCategoryVariation(where: $where, input: $input) {
      message
    }
  }
`;

export const UPDATE_CATEGORY_VARIATION = gql`
  mutation UpdateCategoryVariation(
    $where: UniqueWhereInput!
    $input: UpdateCategoryVariationInput!
  ) {
    updateCategoryVariation(where: $where, input: $input) {
      message
    }
  }
`;

export const DELETE_CATEGORY_VARIATION = gql`
  mutation DeleteCategoryVariation($where: UniqueWhereInput!) {
    deleteCategoryVariation(where: $where) {
      message
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      message
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct(
    $where: UniqueWhereInput!
    $input: UpdateProductInput!
  ) {
    updateProduct(where: $where, input: $input) {
      message
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($where: UniqueWhereInput!) {
    deleteProduct(where: $where) {
      message
    }
  }
`;

export const ADD_INVENTORY = gql`
  mutation AddInventory($input: AddInventoryInput!) {
    addInventory(input: $input) {
      message
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation UpdateInventory(
    $where: UniqueWhereInput!
    $input: UpdateInventoryInput!
  ) {
    updateInventory(where: $where, input: $input) {
      message
    }
  }
`;

export const START_INVENTORY_OUT_SESSION = gql`
  mutation StartInventoryOutSession {
    startInventoryOutSession {
      message
      sessionId
    }
  }
`;

export const OUT_INVENTORY = gql`
  mutation OutInventory($input: OutInventoryInput!) {
    outInventory(input: $input) {
      message
    }
  }
`;
