import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Breadcrumb,
  Button,
  Descriptions,
  Divider,
  Input,
  Spin,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { includes, isEmpty, map, trim, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpandIcon } from '../../assets/svg';
import {
  MODULES,
  ROUTES,
  STATUS,
  defaultDateFormat,
} from '../../common/constants';
import { handlePdfDownload } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import ChangeStatusModal from './ChangeStatusModal';
import GenerateBillModal from './GenerateBillModal';
import {
  UPDATE_ORDER_PRICE,
  UPDATE_SHIPMENT_DETAIL,
} from './graphql/Mutations';
import { FETCH_ORDER } from './graphql/Queries';
import './orders.less';

const OrdersDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [orderItemsData, setOrderItemsData] = useState([]);
  const [generateBillModal, setGenerateBillModal] = useState(false);
  const [shipmentDetail, setShipmentDetail] = useState({});

  const [fetchOrder, { loading, data }] = useLazyQuery(FETCH_ORDER, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.orderAdmin) {
        setOrderItemsData(res?.orderAdmin?.items);
        setShipmentDetail({
          carrierProvider: trim(res?.orderAdmin?.carrierProvider),
          shipmentId: trim(res?.orderAdmin?.shipmentId),
        });
      }
    },
    onError() {},
  });

  useEffect(() => {
    if (id) fetchOrder();
  }, [id]);

  const [updateOrderItemPrice] = useMutation(UPDATE_ORDER_PRICE, {
    onError() {},
  });

  const [updateOrderShipmentDetail, { loading: shipmentLoading }] = useMutation(
    UPDATE_SHIPMENT_DETAIL,
    {
      onError() {},
    },
  );

  const handleSave = () => {
    if (
      isEmpty(shipmentDetail?.carrierProvider) ||
      isEmpty(shipmentDetail?.shipmentId)
    ) {
      message.destroy();
      return message.error('Please provide proper shipment details!');
    }
    updateOrderShipmentDetail({
      variables: {
        where: {
          id,
        },
        input: shipmentDetail,
      },
    }).then(() => fetchOrder());
  };

  const items = [
    {
      key: '1',
      label: 'Order ID',
      children: data?.orderAdmin?.orderNo,
    },
    {
      key: '2',
      label: 'Customer Email',
      children: data?.orderAdmin?.customer?.email,
    },
    {
      key: '3',
      label: 'Placed By',
      children: data?.orderAdmin?.orderBy?.email,
    },
    {
      key: '4',
      label: 'Added On',
      children: dayjs(data?.orderAdmin?.createdAt).format(defaultDateFormat),
    },
    {
      key: '5',
      label: 'Status',
      className: 'status-type',
      children: (
        <div className="d-flex align-center justify-between full-width">
          <p className="m-0">{STATUS[data?.orderAdmin?.status]}</p>
          <Button
            className="d-flex align-center b-0"
            onClick={() => {
              setIsModalOpen(true);
              setStatus(data?.orderAdmin?.status);
            }}
            disabled={data?.orderAdmin?.status === upperCase(STATUS.READY)}
            icon={<ExpandIcon />}
          />
        </div>
      ),
    },
    {
      key: '6',
      label: 'Carrier Provider',
      className: 'carrier-type',
      children: (
        <div className="full-width full-height">
          <Input
            defaultValue={data?.orderAdmin?.carrierProvider}
            placeholder="Enter carrier provider name"
            className="full-height"
            disabled={data?.orderAdmin?.status !== upperCase(STATUS.INVOICED)}
            onBlur={(e) => {
              const {
                target: { value },
              } = e;
              setShipmentDetail({
                ...shipmentDetail,
                carrierProvider: trim(value),
              });
            }}
          />
        </div>
      ),
    },
    {
      key: '7',
      label: 'Shipment ID',
      className: 'carrier-type',
      span: 1,
      children: (
        <div className="full-width full-height">
          <Input
            defaultValue={data?.orderAdmin?.shipmentId}
            placeholder="Enter shipment ID"
            className="full-height"
            disabled={data?.orderAdmin?.status !== upperCase(STATUS.INVOICED)}
            onBlur={(e) => {
              const {
                target: { value },
              } = e;
              setShipmentDetail({
                ...shipmentDetail,
                shipmentId: trim(value),
              });
            }}
          />
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, _, index) => <div>{index + 1}</div>,
    },
    {
      title: 'Product ID',
      dataIndex: ['product', 'code'],
      key: 'product_code',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Variation',
      dataIndex: 'details',
      key: 'variation',
      width: '25%',
      align: 'center',
      render: (details) => (
        <>
          {map(details, (productItem, index) => (
            <p className="m-0" key={index}>
              {productItem?.name}: {productItem?.value?.name}
            </p>
          ))}
        </>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '20%',
      align: 'center',
    },
    {
      title: 'Price (Per Unit)',
      dataIndex: 'pricePerUnit',
      key: 'pricePerUnit',
      width: '20%',
      render: (pricePerUnit, record) => (
        <Input
          className="detail-table-input"
          defaultValue={pricePerUnit}
          placeholder="Enter price"
          disabled={data?.orderAdmin?.status !== upperCase(STATUS.READY)}
          onBlur={(e) => {
            const {
              target: { value },
            } = e;
            if (Number(value) !== pricePerUnit) {
              updateOrderItemPrice({
                variables: {
                  where: {
                    id: record?.id,
                  },
                  input: {
                    pricePerUnit: Number(value),
                  },
                },
              }).then(() => fetchOrder());
            }
          }}
        />
      ),
    },
  ];

  if (loading) return <LoaderComponent />;

  return (
    <div className="orders-details">
      {isModalOpen && (
        <ChangeStatusModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          status={status}
        />
      )}
      {generateBillModal && (
        <GenerateBillModal
          isModalOpen={generateBillModal}
          setIsModalOpen={setGenerateBillModal}
        />
      )}
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: (
                <h1 className="m-0 font-600">{MODULES.ORDER_AND_BILLING}</h1>
              ),
              onClick: () => navigate(ROUTES.ORDER_AND_BILLING),
              className: 'pointer',
            },
            {
              title: (
                <h2 className="m-0 font-600">
                  {data?.orderAdmin?.orderNo ?? ''}
                </h2>
              ),
            },
          ]}
        />
        <Button
          type="primary"
          className="font-600 submit-btn width-percent-10"
          disabled={data?.orderAdmin?.status !== upperCase(STATUS.INVOICED)}
          loading={shipmentLoading}
          onClick={handleSave}
        >
          Save
        </Button>
      </Portal>
      <Spin spinning={loading}>
        <Descriptions
          layout="vertical"
          items={items}
          colon={false}
          column={4}
        />
      </Spin>
      <Divider dashed className="divider-dashed" />
      <div className="mt-20">
        <div className="d-flex justify-between align-center mb-24">
          <h2 className="m-0">ORDER DETAILS</h2>
          <div>
            {data?.orderAdmin?.status === upperCase(STATUS.PLACED) && (
              <Button
                type="default"
                className="font-600 submit-btn mr-10"
                onClick={() => {
                  navigate(`${ROUTES.ORDER_AND_BILLING}/${id}/add-more`);
                }}
              >
                {upperCase('Add more Item')}
              </Button>
            )}
            {!includes(
              [upperCase(STATUS.READY), upperCase(STATUS.PLACED)],
              data?.orderAdmin?.status,
            ) && (
              <Button
                className="font-600 submit-btn mr-10"
                disabled={data?.orderAdmin?.status === upperCase(STATUS.PLACED)}
                onClick={() => {
                  if (data?.orderAdmin?.invoicePdfUrl) {
                    // eslint-disable-next-line no-undef
                    window.open(data?.orderAdmin?.invoicePdfUrl?.url, '_blank');
                  } else {
                    setGenerateBillModal(true);
                  }
                }}
              >
                View PDF
              </Button>
            )}
            <Button
              type="primary"
              className="font-600 submit-btn"
              disabled={data?.orderAdmin?.status === upperCase(STATUS.PLACED)}
              onClick={() => {
                if (data?.orderAdmin?.invoicePdfUrl) {
                  handlePdfDownload(
                    data?.orderAdmin?.invoicePdfUrl?.url,
                    data?.orderAdmin?.orderNo,
                  );
                } else {
                  setGenerateBillModal(true);
                }
              }}
            >
              {data?.orderAdmin?.invoicePdfUrl
                ? 'DOWNLOAD PDF'
                : 'GENERATE INVOICE'}
            </Button>
          </div>
        </div>
        <TableComponent
          loadingData={loading}
          columns={columns}
          data={orderItemsData}
          rowKey={(obj) => obj.id}
        />
      </div>
    </div>
  );
};

export default OrdersDetail;
