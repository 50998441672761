import JsBarcode from 'jsbarcode';
import React, { useEffect, useRef } from 'react';

const LabelPrint = ({
  productName,
  sku,
  range,
  color,
  brand,
  manufacturing,
  month,
  barcodeValue,
}) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    JsBarcode(barcodeRef.current, barcodeValue, {
      format: 'CODE128',
      width: 0.5,
      height: 15,
      displayValue: false,
    });
  }, [barcodeValue]);

  const labelStyle = {
    width: '50mm',
    height: '38mm',
    border: '1px solid black',
    padding: '10px',
    fontSize: '10px',
    lineHeight: '1.2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const infoStyle = {
    textAlign: 'left',
  };

  const brandStyle = {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '5px',
  };

  const barcodeStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  return (
    <div style={labelStyle}>
      <div style={brandStyle}>{brand}</div>
      <div style={infoStyle}>
        <div>Product: {productName}</div>
        <div>SKU: {sku}</div>
        <div>Range: {range}</div>
        <div>Color: {color}</div>
        <div>
          Manufacturing: {month} '{manufacturing}
        </div>
      </div>
      <div style={barcodeStyle}>
        <svg ref={barcodeRef} />
      </div>
      <div style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}>
        Made in India
      </div>
    </div>
  );
};

export default LabelPrint;
