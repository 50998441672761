import { useLazyQuery, useQuery } from '@apollo/client';
import { Breadcrumb, Button, Descriptions, Divider, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeleteIcon,
  DownloadIcon,
  EyeIcon,
  OrderHistoryYellow,
} from '../../assets/svg';
import { MODULES, ROUTES, defaultDateFormat } from '../../common/constants';
import { handlePdfDownload } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import Portal from '../../components/Portal';
import AddContractModal from './AddContractModal';
import './contractor.less';
import { DELETE_CONTRACT } from './graphql/Mutations';
import { GET_CONTACTS, GET_CONTRACTOR } from './graphql/Queries';

const initialUserFilter = {
  filters: { skip: 0, limit: 10 },
  sort: { sortOn: 'createdAt', sortBy: 'DESC' },
};

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const ContractorDetails = () => {
  const { id } = useParams();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [userSessionFilter, setUserSessionFilter] = useState({
    ...initialUserFilter,
    filters: {
      ...initialUserFilter?.filters,
      contractorId: id,
    },
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_CONTRACTOR, {
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [
    getContracts,
    { data: contractData, loading: contractLoading },
  ] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.contracts?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    setUserSessionFilter({
      ...userSessionFilter,
      filters: {
        ...userSessionFilter?.filters,
        contractorId: id,
      },
    });
    getContracts({
      variables: {
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          contractorId: id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setSortedInfo({});
    setUserSessionFilter(initialUserFilter);
    setPaginationProp(initialPaginationValue);
    getContracts({
      variables: {
        ...initialUserFilter,
        filters: { ...initialUserFilter?.filters, contractorId: id },
      },
    });
  };

  const items = [
    {
      key: '1',
      label: 'ID',
      children: data?.contractor?.code,
    },
    {
      key: '2',
      label: 'First Name',
      children: data?.contractor?.firstName,
    },
    {
      key: '3',
      label: 'Last Name',
      children: data?.contractor?.lastName,
    },
    {
      key: '4',
      label: 'Phone Number',
      children: `${data?.contractor?.countryCode} ${data?.contractor?.phoneNo}`,
    },
    {
      key: '5',
      label: 'Added On',
      children: dayjs(data?.contractor?.createdAt).format(defaultDateFormat),
      span: 1,
    },
  ];
  const columns = [
    {
      title: '#',
      key: '#',
      align: 'center',
      width: '10%',
      render: (record, _, index) => (
        <div>{userSessionFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'code' && sortedInfo?.order,
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (date) => dayjs(date).format(defaultDateFormat),
    },

    {
      title: 'Out',
      dataIndex: 'outPdfUrl',
      key: 'outPdfUrl',
      align: 'center',
      render: (pdfUrl) =>
        pdfUrl?.url ? (
          <div className="d-flex justify-center">
            <EyeIcon
              className="mr-10 pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (pdfUrl?.url) {
                  // eslint-disable-next-line no-undef
                  window.open(pdfUrl?.url, '_blank');
                }
              }}
            />
            <DownloadIcon
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                handlePdfDownload(pdfUrl?.url, pdfUrl?.key?.split('/')?.[2]);
              }}
            />
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: 'In',
      dataIndex: 'inPdfUrl',
      key: 'inPdfUrl',
      align: 'center',
      render: (pdfUrl) =>
        pdfUrl?.url ? (
          <div className="d-flex justify-center">
            <EyeIcon
              className="mr-10 pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (pdfUrl?.url) {
                  // eslint-disable-next-line no-undef
                  window.open(pdfUrl?.url, '_blank');
                }
              }}
            />
            <DownloadIcon
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                handlePdfDownload(pdfUrl?.url, pdfUrl?.key?.split('/')?.[2]);
              }}
            />
          </div>
        ) : (
          '-'
        ),
    },
    {
      align: 'center',
      render: (text, record) => (
        <>
          {record?.isLegacy && <OrderHistoryYellow className="mr-10" />}
          <Tooltip title="Delete Contractor">
            <DeleteIcon
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setShowDeleteModal(true);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserSessionFilter({
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      getContracts({
        variables: {
          ...userSessionFilter,
          filters: {
            ...userSessionFilter?.filters,
            skip,
            limit: pagination.pageSize,
            contractorId: id,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setUserSessionFilter({
        ...userSessionFilter,
        filters: {
          ...userSessionFilter?.filters,
          skip,
          limit: pagination.pageSize,
        },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      getContracts({
        variables: {
          ...userSessionFilter,
          filters: {
            ...userSessionFilter?.filters,
            skip,
            limit: pagination.pageSize,
            contractorId: id,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  return (
    <div className="member-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex align-center breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.CONTRACTOR}</h1>,
              onClick: () => navigate(ROUTES.CONTRACTOR),
              className: 'pointer',
            },
            {
              title: <h2 className="m-0 font-600">{data?.contractor?.code}</h2>,
            },
          ]}
        />
      </Portal>

      <Spin spinning={loading}>
        <Descriptions
          layout="vertical"
          items={items}
          colon={false}
          column={4}
        />
      </Spin>
      <Divider dashed className="divider-dashed" />
      <div className="mt-20">
        <div className="d-flex justify-between align-center mb-24">
          <h2 className="m-0">CONTRACT DETAILS</h2>
          <Button
            type="primary"
            className="font-600 submit-btn"
            onClick={() => setIsModalOpen(true)}
          >
            ADD NEW CONTRACT
          </Button>
        </div>
      </div>
      <TableComponent
        loadingData={contractLoading}
        columns={columns}
        data={contractData?.contracts?.contracts}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
        rowClassName="pointer"
        onRow={(record) => ({
          onClick: () => {
            if (!record?.isLegacy) {
              navigate(`${ROUTES.CONTRACTOR}/${id}/${record?.id}`, {
                state: {
                  contractorData: record,
                  mainData: data?.contractor,
                },
              });
            }
          },
        })}
      />
      {isModalOpen && (
        <AddContractModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          isModalOpen={showDeleteModal}
          setIsModalOpen={setShowDeleteModal}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          mutation={DELETE_CONTRACT}
          module="contract"
        />
      )}
    </div>
  );
};

export default ContractorDetails;
