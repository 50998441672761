import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import { LOGIN_ADMIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN_ADMIN, {
    onError() {}, // Always write this method for error handling in all mutation.
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    navigate('/');
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { input: { ...formValues } },
      });
      if (response?.data) {
        const accessToken = response?.data?.loginAdmin?.accessToken;
        const userData = response?.data?.loginAdmin?.user;
        const tokenRefresh = response?.data?.loginAdmin?.refreshToken;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <h1 className="m-0">LOGIN</h1>
            <p>Sign in to your admin account</p>
            <Form
              name="Login"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              form={form}
              className="category-forms"
            >
              <Form.Item
                name="email"
                label="Email Address"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input placeholder="Enter your email address" />
              </Form.Item>

              <Form.Item
                name="password"
                className="mb-8 password-input"
                label="Password"
                rules={[{ required, message: 'Please enter password!' }]}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>
              <Form.Item className="text-right mb-8 forgot-password-link">
                <Link to={ROUTES?.FORGET_PASSWORD}>Forgot password ?</Link>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="full-width mb-0 submit-btn font-600"
                  htmlType="submit"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <div className="text-center login-copyrights">
          Porwal © {new Date()?.getFullYear()}
        </div>
      </div>
    </div>
  );
};

export default Login;
