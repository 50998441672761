import React from 'react';
import JsBarcode from 'react-jsbarcode';

const BarcodeLabel = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      width: '38mm',
      height: '15mm',
      border: '1px solid black',
      padding: '1mm',
      fontFamily: 'Arial',
      fontSize: '8px',
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column', // Column layout to stack items vertically
      justifyContent: 'space-between',
      marginBottom: '5mm',
    }}
  >
    {/* Title with "श्री" centered */}
    <div
      style={{
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '5px',
        marginBottom: '1mm',
      }}
    >
      श्री
    </div>

    {/* Content area split into two sides */}
    <div
      style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}
    >
      <div style={{ alignSelf: 'flex-start' }}>
        <div>SKU: {props.sku}</div>
        <div>RANGE: {props.range}</div>
        <div>COL: {props.color}</div>
      </div>
      <div style={{ textAlign: 'center', alignSelf: 'flex-end' }}>
        <div style={{ fontWeight: 'bold' }}>{props.brand}</div>
        <JsBarcode
          value={props.barcodeValue}
          options={{
            width: 0.2, // Adjust width for DPI
            height: 10, // Adjust height for better printing
            displayValue: false,
            background: '#ffffff', // Ensure white background
            lineColor: '#000000', // Black barcode lines
          }}
        />
      </div>
    </div>
  </div>
));

export default BarcodeLabel;
