import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Radio, Row, Select } from 'antd';
import { map, trim, upperCase } from 'lodash';
import React from 'react';
import IndianStates from '../../assets/json/indian-states.json';
import { PROOF_TYPE, REGEX } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from './graphql/Mutations';

const { email } = formValidatorRules;

function AddEditCustomerModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  selectedRecord,
  setSelectedRecord,
}) {
  const getIndianStates = () => IndianStates;

  const IndianState = getIndianStates();
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const proofType = Form.useWatch('proofType', form);

  const [userMutation, { loading }] = useMutation(
    selectedRecord ? UPDATE_CUSTOMER : CREATE_CUSTOMER,
    {
      onCompleted() {
        setSelectedRecord();
        setIsModalOpen(false);
        refetch();
      },
      onError: () => { },
    },
  );

  const onFinish = async (values) => {
    userMutation({
      variables: {
        input: {
          ...values,
          state: values?.state?.value || values?.state,
          country: 'India',
          name: trim(values?.name),
          customCode: trim(values?.customCode),
          countryCode: '+91',
        },
        ...(selectedRecord && {
          where: { id: selectedRecord?.id },
        }),
      },
    });
  };

  return (
    <div id="add-edit-member-modal">
      <Modal
        centered
        title={
          <h3 className="m-0 mb-10">
            {upperCase(`${selectedRecord ? 'Edit' : 'Add New'} Customer`)}
          </h3>
        }
        footer={null}
        onCancel={() => {
          setSelectedRecord();
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        width={576}
      >
        <Form
          name="basic"
          initialValues={
            selectedRecord
              ? { ...selectedRecord }
              : { proofType: PROOF_TYPE.PAN, countryCode: '+91' }
          }
          className="category-forms mt-16"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please input name!',
                  },
                ]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Custom Code"
                name="customCode"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please input code!',
                  },
                ]}
              >
                <Input placeholder="Enter custom code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  email,
                ]}
              >
                <Input placeholder="Enter email address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact Details"
                name="phoneNo"
                rules={[
                  {
                    required: true,
                    message: 'Please input phone number!',
                  },
                  {
                    pattern: REGEX.PHONE,
                    message: 'Enter Valid Phone Number',
                  },
                ]}
              >
                <Input placeholder="Enter Phone Number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: 'Please select state!',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="children"
                  labelInValue
                >
                  {map(IndianState, (record) => (
                    <Select.Option key={record?.isoCode} value={record?.name}>
                      {record?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: 'Please Enter city!',
                  },
                ]}
              >
                <Input placeholder="Enter City" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Zipcode"
                name="zipCode"
                rules={[
                  {
                    required: true,
                    message: 'Please input zipcode',
                  },
                  { pattern: REGEX.ZIPCODE, message: 'Enter Valid Zipcode' },
                ]}
              >
                <Input placeholder="Enter zipcode" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country Code"
                name="countryCode"
                rules={[
                  {
                    required: true,
                    message: 'Please input country code',
                  },
                ]}
              >
                <Input disabled placeholder="Enter country code" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Proof Type"
            name="proofType"
            rules={[
              {
                required: true,
                message: 'Required!',
              },
            ]}
          >
            <Radio.Group>
              <Radio value={PROOF_TYPE.PAN}>{PROOF_TYPE.PAN}</Radio>
              <Radio value={PROOF_TYPE.GST}>{PROOF_TYPE.GST}</Radio>
              <Radio value={PROOF_TYPE.AADHAAR}>{PROOF_TYPE.AADHAAR}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Proof Number"
            name="proofNo"
            rules={[
              {
                required: true,
                message: 'Please input proof number',
              },
              {
                pattern: REGEX[proofType],
                message: 'Please enter valid proof number',
              },
            ]}
            dependencies={['proofType']}
          >
            <Input placeholder="Enter proof number" />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="full-width font-600 submit-btn"
            disabled={loading}
            loading={loading}
          >
            {selectedRecord ? `Edit` : `Add`} Customer
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default AddEditCustomerModal;
