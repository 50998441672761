import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Upload } from 'antd';
import { isEmpty, isObject, trim, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Delete, ImageIcon } from '../../assets/svg';
import { fileUpload, getBase64File } from '../../common/utils';
import { CREATE_CATEGORY, UPDATE_CATEGORY } from './graphql/Mutations';
import { GET_CATEGORY_IMAGE_UPLOAD_URL } from './graphql/Queries';

function AddEditModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  selectedRecord,
  setSelectedRecord,
}) {
  const [form] = Form.useForm();
  const [uploadedImage, setUploadedImage] = useState({});
  const [loading, setLoading] = useState(false);
  const [getCategoryImageUploadSignedUrl] = useLazyQuery(
    GET_CATEGORY_IMAGE_UPLOAD_URL,
    {
      onError: () => {},
    },
  );
  const [createUpdateCategory] = useMutation(
    selectedRecord ? UPDATE_CATEGORY : CREATE_CATEGORY,
    {
      onError: () => {
        setLoading(false);
      },
    },
  );

  useEffect(() => {
    if (selectedRecord) {
      setUploadedImage({
        data: { name: selectedRecord?.image?.key?.split('/')?.[1] },
        url: selectedRecord?.image?.url,
      });
    }
  }, [selectedRecord]);

  const onFinish = async (values) => {
    setLoading(true);
    const { image } = values;
    const payload = {
      ...values,
      name: trim(values?.name),
    };
    if (!image?.url) {
      const res = await getCategoryImageUploadSignedUrl({
        variables: {
          input: {
            contentLength: image?.file?.size,
            fileName: image?.file?.name,
          },
        },
      });
      if (res?.data?.getCategoryImageUploadSignedUrl?.signedUrl) {
        await fileUpload(
          res?.data?.getCategoryImageUploadSignedUrl?.signedUrl,
          image?.file,
        );
        payload.image = res?.data?.getCategoryImageUploadSignedUrl?.key;
      }
    } else {
      payload.image = image?.key;
    }
    createUpdateCategory({
      variables: {
        input: payload,
        ...(selectedRecord && {
          where: {
            id: selectedRecord?.id,
          },
        }),
      },
    }).then((res) => {
      if (res?.data) {
        setLoading(false);
        setIsModalOpen(false);
        setSelectedRecord(null);
        refetch();
      }
    });
  };

  const handleImageUploadChange = async ({ file }) => {
    if (!isObject(file)) {
      return;
    }
    const fileType = file.type;
    if (!fileType.startsWith('image/')) {
      return;
    }
    const isJpgOrPng =
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/jpeg';
    if (!isJpgOrPng) {
      return;
    }
    const isLt5M = file?.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      return;
    }
    getBase64File(file, (imageUrl) => {
      setUploadedImage({ data: file, url: imageUrl });
    });
  };

  return (
    <div id="add-edit-categories-modal">
      <Modal
        centered
        title={
          <h3 className="m-0 mb-10">
            {upperCase(`${selectedRecord ? 'Edit' : 'Add'} Category`)}
          </h3>
        }
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={576}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            name: selectedRecord?.name,
            image: selectedRecord
              ? {
                  url: selectedRecord?.image?.url,
                  key: selectedRecord?.image?.key,
                }
              : [],
          }}
          className="category-forms mt-16"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter name!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter category name" />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            className="mb-12"
            rules={[
              {
                required: true,
                message: 'Please select image!',
              },
              () => ({
                validator(_, value) {
                  if (value?.file) {
                    const isJpgOrPng =
                      value?.file.type === 'image/png' ||
                      value?.file.type === 'image/jpg' ||
                      value?.file.type === 'image/jpeg';
                    if (!isJpgOrPng) {
                      return Promise.reject(
                        new Error('You can only upload JPG/PNG file!'),
                      );
                    }
                    const isLt5M = value?.file?.size / 1024 / 1024 < 5;
                    if (!isLt5M) {
                      return Promise.reject(
                        new Error('Image must smaller than 5 MB!'),
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Upload
              beforeUpload={() => false}
              showUploadList={false}
              multiple={false}
              className="category-img-upload"
              accept=".jpg, .png"
              onChange={handleImageUploadChange}
            >
              {!isEmpty(uploadedImage) && (
                <div
                  className="d-flex align-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="image">
                    <img
                      src={uploadedImage?.url}
                      alt="avatar"
                      className="mr-6"
                      width={80}
                      height={80}
                    />
                  </div>
                  <p className="m-0 image-name">{uploadedImage?.data?.name}</p>
                </div>
              )}
              <div className="d-flex">
                {!isEmpty(uploadedImage) && (
                  <Button
                    type="dashed"
                    className="full-width upload-button mr-12"
                    icon={<Delete height={20} width={20} />}
                    onClick={(e) => {
                      setUploadedImage({});
                      form?.setFieldsValue({ image: [] });
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    Delete Image
                  </Button>
                )}
                <Button
                  type="dashed"
                  className="full-width upload-button"
                  icon={<ImageIcon height={20} width={20} />}
                >
                  {!isEmpty(uploadedImage) ? 'Replace' : 'Upload'} Image
                </Button>
              </div>
            </Upload>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="full-width font-600 submit-btn"
            disabled={loading}
            loading={loading}
          >
            {upperCase(`${selectedRecord ? 'Update' : 'Add'} Category`)}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default AddEditModal;
