import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Empty, Form, Input, Modal, Row, Select } from 'antd';
import { debounce, filter, includes, map, uniqBy, upperCase } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { LIMIT } from '../../../../common/constants';
import { normalizeNegativeNumber } from '../../../../common/utils';
import LoaderComponent from '../../../../components/LoaderComponent';
import { ADD_INVENTORY, UPDATE_INVENTORY } from '../../graphql/Mutations';
import { GET_CATEGORY_VARIATIONS, GET_PRODUCTS } from '../../graphql/Queries';

const { Option } = Select;
let categoryScrollDebounceJob;

const AddEditInventory = ({
  openModal,
  setOpenModal,
  editData,
  refetch,
  setEditData,
  categoryId,
}) => {
  const [form] = Form.useForm();
  const [productLoading, setProductLoading] = useState(false);
  const [productSearchFlag, setProductSearchFlag] = useState(false);
  const [productData, setProductData] = useState([]);
  const [productIsEnd, setProductIsEnd] = useState(false);
  const [productDebounceCall, setProductDebounceCall] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [variationLoading, setVariationLoading] = useState(false);
  const [variationData, setVariationData] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState({});
  const [fetchCategories] = useLazyQuery(GET_PRODUCTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setProductIsEnd(res?.productsAdmin?.products?.length < LIMIT);
      if (productSearchFlag) {
        setProductData(uniqBy([...res?.productsAdmin?.products], 'id'));
      } else {
        if (editData) {
          const tempProduct = [
            editData?.product,
            ...res?.productsAdmin?.products,
          ];
          setProductData(uniqBy([...productData, ...tempProduct], 'id'));
          return;
        }
        setProductData(
          uniqBy([...productData, ...res?.productsAdmin?.products], 'id'),
        );
      }
      setProductLoading(false);
    },
    onError() {},
  });

  const [categoryVariations] = useLazyQuery(GET_CATEGORY_VARIATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setVariationData(
        uniqBy(
          [...variationData, ...res?.categoryVariations?.categoryVariations],
          'id',
        ),
      );
      setVariationLoading(false);
    },
    onError() {},
  });

  const [addInventory, { loading }] = useMutation(ADD_INVENTORY, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {
      setOpenModal();
      refetch();
    },
  });

  const [updateInventory, { loading: updateLoading }] = useMutation(
    UPDATE_INVENTORY,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted() {
        setOpenModal();
        refetch();
      },
    },
  );

  useEffect(() => {
    fetchCategories({
      variables: {
        filters: {
          skip: 0,
          limit: 10,
          search: searchValue,
          categoryId,
        },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      },
    });
    categoryVariations({
      variables: {
        filters: {
          bypassPagination: true,
          categoryId,
        },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      },
    });
  }, []);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({ ...editData, productId: editData?.product?.id });
      // setAttachmentType(editData?.attachmentType);
    }
  }, [editData]);

  const handleFinish = async (values) => {
    if (editData) {
      updateInventory({
        variables: {
          where: { id: editData?.id },
          input: { quantity: Number(values?.quantity) },
        },
      });
    } else {
      addInventory({
        variables: {
          input: {
            ...values,
            quantity: Number(values?.quantity),
          },
        },
      });
    }
  };

  const onCategoryScroll = (event) => {
    setProductSearchFlag(false);
    if (categoryScrollDebounceJob) {
      categoryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    categoryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !productIsEnd) {
        setProductLoading(true);
        setProductDebounceCall((prevState) => prevState + 1);
        fetchCategories({
          variables: {
            filters: {
              limit: 10,
              skip: (productDebounceCall + 1) * LIMIT,
              search: searchValue,
              categoryId,
            },
            sort: { sortOn: 'createdAt', sortBy: 'DESC' },
          },
        });
      }
    }, 500);

    categoryScrollDebounceJob();
  };

  const handleCategoryChange = (value) => {
    setProductSearchFlag(true);
    setSearchValue(value);
    if (value) {
      setProductLoading(true);
      fetchCategories({
        variables: {
          filters: {
            skip: 0,
            limit: 10,
            search: value,
            categoryId,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    } else {
      setProductLoading(true);
      fetchCategories({
        variables: {
          filters: {
            skip: 0,
            limit: 10,
            search: value,
            categoryId,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCategoryHandler = useCallback(
    debounce(handleCategoryChange, 500),
    [],
  );

  const handleCategoryBlur = () => {
    setSearchValue('');
    setProductDebounceCall(0);
    setProductIsEnd(false);
    setProductData([]);
    fetchCategories({
      variables: {
        filters: { skip: 0, limit: 10, categoryId },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      },
    });
  };

  const handleCategoryClear = () => {
    form?.setFieldsValue({
      categoryId: null,
    });
    setProductData([]);
    fetchCategories({
      variables: {
        filters: { skip: 0, limit: 10, categoryId },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      },
    });
  };

  const handleChange = () => {
    setSearchValue('');
    fetchCategories({
      variables: {
        filters: { skip: 0, limit: 10, categoryId },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      },
    });
  };

  const onVariationChange = (value, index, fields, field) => {
    setSelectedVariation({
      ...selectedVariation,
      [index]: filter(variationData, (record) => record?.key === value),
    });
    form?.setFields([
      { name: ['variations', field?.name, 'value'], value: null },
    ]);
    if (fields) {
      fields.forEach(({ name }) => {
        if (name !== field?.name) {
          form.validateFields([['variations', name, 'key']]);
        }
      });
    }
  };

  return (
    <Modal
      open={openModal}
      title={
        <h2 className="m-0 mb-10">
          {upperCase(`${editData ? 'Edit' : 'Add New'} Inventory`)}
        </h2>
      }
      centered
      onCancel={() => {
        setOpenModal(false);
        setEditData(null);
      }}
      footer={null}
    >
      <Form
        layout="vertical"
        className="category-forms mt-16"
        form={form}
        onFinish={handleFinish}
        initialValues={{ variations: [{ key: null, value: null }] }}
      >
        <Form.Item
          label="Product"
          name="productId"
          rules={[{ required: true, message: 'Please enter name!' }]}
        >
          <Select
            disabled={editData}
            notFoundContent={
              productLoading ? (
                <LoaderComponent size="small" setHeight={10} />
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            placeholder="Select product"
            allowClear
            onBlur={handleCategoryBlur}
            onSearch={debouncedCategoryHandler}
            onClear={handleCategoryClear}
            onPopupScroll={onCategoryScroll}
            showSearch
            onChange={handleChange}
            filterOption={false}
          >
            {map(productData, (item) => (
              <Option key={item?.id} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {!editData && (
          <>
            <p>
              Variations <span className="required-sign">*</span>
            </p>
            <Form.List name={['variations']} label="Variations">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map((field, index) => (
                    <Row gutter={16} key={field?.key} align="middle">
                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field?.name, 'key']}
                          key={[field?.key, 'key']}
                          rules={[
                            () => ({
                              validator() {
                                const value = form.getFieldValue([
                                  'variations',
                                  field?.name,
                                  'key',
                                ]);
                                if (!value) {
                                  return Promise.reject(
                                    new Error('Please select variation'),
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Select
                            disabled={editData}
                            notFoundContent={
                              variationLoading ? (
                                <LoaderComponent size="small" setHeight={10} />
                              ) : (
                                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                              )
                            }
                            placeholder="Select Variation"
                            allowClear
                            showSearch
                            onChange={(e) => {
                              onVariationChange(e, index, fields, field);
                            }}
                          >
                            {map(
                              filter(
                                variationData,
                                (data) =>
                                  !includes(
                                    map(
                                      form.getFieldValue('variations'),
                                      'key',
                                    ),
                                    data?.key,
                                  ),
                              ),
                              (item) => (
                                <Option key={item?.key} value={item?.key}>
                                  {item?.name}
                                </Option>
                              ),
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field?.name, 'value']}
                          key={[field?.key, 'value']}
                          rules={[
                            { required: true, message: 'Please select value!' },
                          ]}
                        >
                          <Select
                            disabled={editData}
                            notFoundContent={
                              productLoading ? (
                                <LoaderComponent size="small" setHeight={10} />
                              ) : (
                                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                              )
                            }
                            placeholder="Select Value"
                            allowClear
                            showSearch
                            onChange={onVariationChange}
                          >
                            {map(
                              selectedVariation?.[index]?.[0]?.values,
                              (item) => (
                                <Option key={item?.key} value={item?.key}>
                                  {item?.name}
                                </Option>
                              ),
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      {fields?.length > 1 ? (
                        <Col span={2}>
                          <MinusCircleOutlined
                            className="mb-24"
                            onClick={() => {
                              remove(field?.name);
                              fields.forEach(({ name }) => {
                                if (name !== field?.name) {
                                  form.validateFields([
                                    ['variations', name, 'key'],
                                  ]);
                                }
                              });
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          key: null,
                          value: null,
                        })
                      }
                      className="full-width"
                      icon={<PlusOutlined />}
                    >
                      Add Another Variation
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        )}
        <Form.Item
          label="Quantity"
          name="quantity"
          rules={[
            {
              required: true,
              message: 'Please input quantity',
            },
          ]}
          normalize={normalizeNegativeNumber}
        >
          <Input
            placeholder="Enter quantity"
            type="number"
            className="number-input"
          />
        </Form.Item>
        <Button
          type="primary"
          className="full-width font-600 submit-btn"
          onClick={() => {
            form.submit();
          }}
          loading={updateLoading || loading}
          disabled={updateLoading || loading}
        >
          {`${editData ? 'Update' : 'Add'} Variation`}
        </Button>
      </Form>
    </Modal>
  );
};

export default AddEditInventory;
