import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Select } from 'antd';
import { filter, map, trim, upperCase } from 'lodash';
import React from 'react';
import {
  CREATE_CATEGORY_VARIATION,
  UPDATE_CATEGORY_VARIATION,
} from '../../graphql/Mutations';

const AddEditVariationModal = ({
  isOpen,
  setIsOpen,
  editData = null,
  id,
  refetch,
  setEditData,
}) => {
  const [form] = Form.useForm();

  const [createUpdateCategoryVariation, { loading }] = useMutation(
    editData ? UPDATE_CATEGORY_VARIATION : CREATE_CATEGORY_VARIATION,
    {
      onError() {},
    },
  );

  const handleFinish = async (values) => {
    const payload = {
      ...values,
      name: trim(values?.name),
    };
    if (editData) delete payload?.name;
    const response = await createUpdateCategoryVariation({
      variables: {
        input: payload,
        where: {
          id: editData?.id ?? id,
        },
      },
    });
    if (response?.data) {
      setIsOpen(false);
      setEditData(null);
      if (refetch) refetch();
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
        setEditData(null);
      }}
      centered
      footer={null}
      title={
        <h2 className="m-0 mb-10">
          {upperCase(`${editData ? 'Edit' : 'Add'} Variation`)}
        </h2>
      }
    >
      <Form
        layout="vertical"
        className="category-forms mt-16"
        form={form}
        onFinish={handleFinish}
        initialValues={{
          ...editData,
          values: map(editData?.values, (item) => item?.name),
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please enter name!', whitespace: true },
          ]}
        >
          <Input placeholder="Enter variation name" disabled={editData} />
        </Form.Item>
        <Form.Item
          label="Values"
          name="values"
          rules={[
            {
              required: true,
              message: 'Please enter value(s)!',
            },
          ]}
          normalize={(values) => filter(values, (item) => trim(item))}
        >
          <Select
            mode="tags"
            tokenSeparators={[',']}
            size="default"
            dropdownStyle={{ display: 'none' }}
            placeholder="Enter values"
            showSearch={false}
            suffixIcon={<></>}
            allowClear
            className="custom-select"
          />
        </Form.Item>
        <Button
          type="primary"
          className="full-width font-600 submit-btn"
          onClick={() => {
            form.submit();
          }}
          loading={loading}
          disabled={loading}
        >
          {`${editData ? 'Update' : 'Add'} Variation`}
        </Button>
      </Form>
    </Modal>
  );
};

export default AddEditVariationModal;
