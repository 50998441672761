import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Form, Input, Row, Space, Upload } from 'antd';
import { isEmpty, isObject } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageIcon } from '../../assets/svg';
import { MODULES, ROUTES } from '../../common/constants';
import { formValidatorRules, getBase64File } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import Portal from '../../components/Portal';
import ChangePasswordModal from '../auth/ChangePasswordModal';
import { UPDATE_PROFILE } from '../auth/graphql/Mutations';
import { PROFILE } from '../auth/graphql/Queries';

const { required, name, email } = formValidatorRules;

function Profile() {
  const [uploadedImage, setUploadedImage] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form?.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const { data: userData } = useQuery(PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.profile) {
        setLoading(false);
        form?.setFieldsValue({ ...res?.profile });
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const [updateCurrentUser] = useMutation(UPDATE_PROFILE, {
    onError: () => {
      form?.setFieldsValue(userData);
      setBtnLoading(false);
    },
  });

  // eslint-disable-next-line no-unused-vars
  const handleImageUploadChange = async ({ file }) => {
    if (!isObject(file)) {
      return;
    }
    const fileType = file.type;
    if (!fileType.startsWith('image/')) {
      return;
    }
    const isJpgOrPng =
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/jpeg';
    if (!isJpgOrPng) {
      return;
    }
    const isLt5M = file?.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      return;
    }
    getBase64File(file, (imageUrl) => {
      setUploadedImage({ data: file, url: imageUrl });
    });
  };

  const handleBack = () => {
    navigate(ROUTES.MAIN);
  };

  const onFinish = async (values) => {
    setBtnLoading(true);
    const userObj = {
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
      // commented as of now.
      // profileImage: values?.profileImage
    };
    await updateCurrentUser({
      variables: {
        input: userObj,
      },
    });
    setBtnLoading(false);
  };

  return (
    <>
      <Portal portalId="header-wrapper">
        <h1 className="m-0">{MODULES.PROFILE}</h1>
      </Portal>
      {openModal && (
        <ChangePasswordModal
          isModalOpen={openModal}
          setIsModalOpen={setOpenModal}
        />
      )}
      {loading ? (
        <LoaderComponent />
      ) : (
        <Form
          form={form}
          className="sticky-action-form category-forms"
          layout="vertical"
          onFinish={onFinish}
        >
          <Card
            className="ant-body-scroll"
            actions={[
              <div
                key="actionbutton"
                className="d-flex justify-between align-center"
              >
                <Button
                  type="link"
                  className="change-password-btn"
                  onClick={() => setOpenModal(true)}
                >
                  Change Password
                </Button>
                <div className="text-right">
                  <Space>
                    <Button onClick={handleBack} disabled={btnLoading}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      loading={btnLoading}
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Space>
                </div>
              </div>,
            ]}
          >
            <div className="card-body-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={24} xl={24}>
                  <Form.Item
                    // name="profileImage"
                    label="Profile Image"
                    rules={[
                      // for future use
                      // {
                      //   required: true,
                      //   message: 'Required!',
                      // },
                      () => ({
                        validator(_, value) {
                          if (value) {
                            if (value?.file) {
                              const isJpgOrPng =
                                value?.file.type === 'image/png' ||
                                value?.file.type === 'image/jpg';
                              if (!isJpgOrPng) {
                                return Promise.reject(
                                  new Error(
                                    'You can only upload JPG/PNG file!',
                                  ),
                                );
                              }
                              const isLt2M =
                                value?.file?.size / 1024 / 1024 < 2;
                              if (!isLt2M) {
                                return Promise.reject(
                                  new Error('Image must smaller than 2MB!'),
                                );
                              }
                              return Promise.resolve();
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      showUploadList={false}
                      multiple={false}
                      className="category-img-upload"
                      accept=".jpg, .png"
                      // onChange={handleImageUploadChange}
                      disabled
                    >
                      {!isEmpty(uploadedImage) && (
                        <div
                          className="d-flex align-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="image">
                            <img
                              src={uploadedImage?.url}
                              alt="avatar"
                              className="mr-6"
                              width={80}
                              height={80}
                            />
                          </div>
                          <p className="m-0 image-name">
                            {uploadedImage?.data?.name}
                          </p>
                        </div>
                      )}
                      <div className="d-flex">
                        <Button
                          type="primary"
                          className="profile-upload-button d-flex h-40 align-center mt-8"
                          icon={<ImageIcon height={20} width={20} />}
                          disabled
                        >
                          {!isEmpty(uploadedImage) ? 'Replace' : 'Upload'} Image
                        </Button>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { ...required, message: 'Please Enter First Name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { ...required, message: 'Please Enter Last Name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24} xl={8}>
                  <Form.Item
                    name="email"
                    label="Email Id"
                    rules={[
                      { ...required, message: 'Please Enter Email' },
                      email,
                    ]}
                  >
                    <Input disabled placeholder="Enter Email Id" />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
        </Form>
      )}
    </>
  );
}
export default Profile;
