import { useLazyQuery } from '@apollo/client';
import { Button, Popover, Tag } from 'antd';
import dayjs from 'dayjs';
import { capitalize, map, slice, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DeleteIcon, EditIcon } from '../../../../assets/svg';
import { defaultDateFormat } from '../../../../common/constants';
import TableComponent from '../../../../components/CommonTable';
import DeleteConfirmModal from '../../../../components/DeleteConfirmModal';
import Portal from '../../../../components/Portal';
import SearchComponent from '../../../../components/SearchComponent';
import { DELETE_CATEGORY_VARIATION } from '../../graphql/Mutations';
import { GET_CATEGORY_VARIATIONS } from '../../graphql/Queries';
import AddEditVariationModal from './AddEditVariationModal';

const CategoryVariations = ({ id }) => {
  const initialVariationsFilter = {
    skip: 0,
    limit: 10,
    categoryId: id,
  };

  const initialVariationsSort = {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [variationsFilter, setVariationsFilter] = useState(
    initialVariationsFilter,
  );
  const [variationsSort, setVariationsSort] = useState(initialVariationsSort);
  const [search, setSearch] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [fetchCategoryVariations, { loading, data, refetch }] = useLazyQuery(
    GET_CATEGORY_VARIATIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.categoryVariations?.count,
        };
        setPaginationProp(pagination);
      },
      variables: {
        filters: { ...variationsFilter, search },
        sort: variationsSort,
      },
      onError() {},
    },
  );

  useEffect(() => {
    if (id) {
      fetchCategoryVariations({
        variables: {
          filters: { ...variationsFilter, search },
          sort: variationsSort,
        },
      });
    }
  }, [id]);

  const onSearchChange = async (value) => {
    setSearch(trim(value));
    setVariationsFilter({ ...variationsFilter, skip: 0, search: value });
    fetchCategoryVariations({
      variables: {
        sort: variationsSort,
        filters: {
          ...variationsFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, datas, index) => (
        <div>{variationsFilter?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    },
    {
      title: 'Values',
      dataIndex: 'values',
      key: 'values',
      render: (value) => (
        <div>
          {map(slice(value, 0, 5), (item) => (
            <Tag className="mb-6 variations-tag" key={item?.id}>
              {capitalize(item?.name)}
            </Tag>
          ))}{' '}
          {value?.length > 5 && (
            <Popover
              content={
                <div>
                  {map(slice(value, 5), (item) => (
                    <Tag className="mb-6 variations-tag" key={item?.id}>
                      {capitalize(item?.name)}
                    </Tag>
                  ))}
                </div>
              }
              placement="right"
            >
              <Button type="link">+ {value?.length - 5}</Button>
            </Popover>
          )}
        </div>
      ),
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (value) => dayjs(value).format(defaultDateFormat),
    },
    {
      align: 'center',
      render: (value, record) => (
        <div>
          <EditIcon
            className="mr-10 pointer"
            onClick={() => {
              setOpenModal(true);
              setEditData(record);
            }}
          />
          <DeleteIcon
            className="pointer"
            onClick={() => {
              setShowDeleteModal(true);
              setEditData(record);
            }}
          />
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setVariationsSort({
        sortOn: sorter.field,
        sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      });
      setVariationsFilter({
        ...variationsFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchCategoryVariations({
        variables: {
          filters: {
            ...variationsFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setVariationsSort({
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      setVariationsFilter({
        ...variationsFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchCategoryVariations({
        variables: {
          filters: {
            ...variationsFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  return (
    <div>
      {openModal && (
        <AddEditVariationModal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          id={id}
          refetch={refetch}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          isModalOpen={showDeleteModal}
          setIsModalOpen={setShowDeleteModal}
          refetch={refetch}
          selectedRecord={editData}
          setSelectedRecord={setEditData}
          mutation={DELETE_CATEGORY_VARIATION}
          module="variation"
        />
      )}
      <Portal portalId="add-category-details">
        <div className="d-flex">
          <SearchComponent
            name="variations"
            getData={onSearchChange}
            className="category-search search-component mr-16"
          />
          <Button
            type="primary"
            className="add-new-btn"
            onClick={() => setOpenModal(true)}
          >
            Add New
          </Button>
        </div>
      </Portal>

      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.categoryVariations?.categoryVariations ?? []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        scroll={{ y: 450 }}
      />
    </div>
  );
};

export default CategoryVariations;
