import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($where: UniqueWhereInput!, $input: UpdateUserInput!) {
    updateUser(where: $where, input: $input) {
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: UniqueWhereInput!) {
    deleteUser(where: $where) {
      message
    }
  }
`;
