import { IdcardOutlined } from '@ant-design/icons';
import { Avatar, Menu, Space, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { LogoutIcon } from '../../../assets/svg';
import { MODULES, ROUTES } from '../../../common/constants';
import { getItem } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';

const UserProfile = () => {
  const { getCurrentUser } = useContext(AppContext);
  const {
    location: { pathname },
  } = useRouter();
  const navigate = useNavigate();
  const { firstName = '', profilePicture = '', email = '', lastName = '' } =
    getCurrentUser() || {};
  const items = [
    getItem(
      MODULES?.PROFILE,
      ROUTES?.PROFILE,
      <IdcardOutlined height={18} width={18} />,
    ),
  ];

  const onMenuSelect = (e) => {
    navigate(e?.key);
  };

  return (
    <div>
      <Menu
        className="mb-12"
        theme="light"
        mode="inline"
        selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={items}
      />
      <div className="d-flex align-center">
        <Space className="d-flex align-center gap-4 profile-data" size="small">
          <Avatar
            size={40}
            alt="Avatar"
            src={profilePicture ?? ''}
          >{`${firstName?.[0]}${lastName?.[0]}`}</Avatar>
          <div className="user-detail">
            <div className="name">{`${firstName} ${lastName && lastName}`}</div>
            <div className="email" title={email}>
              {email}
            </div>
          </div>
        </Space>
        <Tooltip title="logout">
          <LogoutIcon
            className="pointer"
            onClick={() => {
              navigate(ROUTES?.LOGOUT);
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default UserProfile;
