import { useLazyQuery, useMutation } from '@apollo/client';
import { Breadcrumb, Col, Divider, Input, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MODULES, ROUTES } from '../../../../common/constants';
import TableComponent from '../../../../components/CommonTable';
import Portal from '../../../../components/Portal';
import ScannerInput from '../../../ordersBilling/Scanner';
import { OUT_INVENTORY } from '../../graphql/Mutations';
import { GET_SESSION_INVENTORIES } from '../../graphql/Queries';

const { Search } = Input;
const InventoryOut = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const initialInventoriesFilter = {
    skip: 0,
    limit: 10,
    sessionId,
  };
  const initialInventoriesSort = {
    sortOn: 'actionDate',
    sortBy: 'DESC',
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [inventoriesFilter, setInventoriesFilter] = useState(
    initialInventoriesFilter,
  );
  const [inventoriesSort, setInventoriesSort] = useState(
    initialInventoriesSort,
  );

  const [fetchSessionInventories, { loading, data, refetch }] = useLazyQuery(
    GET_SESSION_INVENTORIES,
    {
      variables: {
        filters: { ...inventoriesFilter },
        sort: inventoriesSort,
      },
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.sessionInventories?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  const [outInventory] = useMutation(OUT_INVENTORY, { onError() {} });

  useEffect(() => {
    if (sessionId) fetchSessionInventories();
  }, [sessionId]);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setInventoriesSort({
        sortOn: sorter.field,
        sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      });
      setInventoriesFilter({
        ...inventoriesFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchSessionInventories({
        variables: {
          filters: {
            ...inventoriesFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setInventoriesSort({
        sortOn: 'actionDate',
        sortBy: 'DESC',
      });
      setInventoriesFilter({
        ...inventoriesFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchSessionInventories({
        variables: {
          filters: {
            ...inventoriesFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'actionDate', sortBy: 'DESC' },
        },
      });
    }
  };

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, datas, index) => (
        <div>{inventoriesFilter?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Name',
      dataIndex: ['product', 'name'],
      key: 'product_name',
    },
    {
      title: 'Variation',
      dataIndex: ['productVariation', 'code'],
      key: 'variation',
      align: 'center',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];

  const onScanComplete = (barCode, { source = '' }) => {
    if (source !== 'clear')
      if (barCode) {
        outInventory({
          variables: {
            input: {
              barCode,
              sessionId,
            },
          },
        }).then((res) => {
          if (res.data) {
            refetch();
          }
        });
      } else {
        message.destroy();
        message.error('Please scan or enter the barcode.');
      }
  };

  return (
    <div className="orders-details">
      <Portal portalId="header-wrapper">
        <Breadcrumb
          className="d-flex breadcrumb-title"
          items={[
            {
              title: <h1 className="m-0 font-600">{MODULES.CATEGORIES}</h1>,
              onClick: () => navigate(ROUTES.CATEGORIES),
              className: 'pointer',
            },
            {
              title: <h2 className="m-0 font-600">Inventory Out</h2>,
            },
          ]}
        />
      </Portal>
      <Row gutter={10} className="mb-20">
        <Col span={12}>
          <p className="top-label">Product Variation Code</p>
          <Search
            placeholder="Enter product variation code"
            allowClear
            enterButton="Add"
            size="large"
            onSearch={(e, event, info) => onScanComplete(e, info)}
          />
        </Col>
        <Col span={12}>
          <p className="top-label">Scanned Product Code</p>
          <ScannerInput onScanComplete={onScanComplete} />
        </Col>
      </Row>
      <Divider dashed className="divider-dashed" />
      <div className="mt-20">
        <div className="d-flex justify-between align-center mb-24">
          <h2 className="m-0">ORDER DETAILS</h2>
        </div>
        <TableComponent
          loadingData={loading}
          columns={columns}
          data={data?.sessionInventories?.inventories}
          rowKey={(obj) => obj?.id}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          tableClassName="products-table"
          scroll={{ y: 458 }}
        />
      </div>
    </div>
  );
};

export default InventoryOut;
