import { useLazyQuery } from '@apollo/client';
import { Button, Col, Row, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../assets/svg';
import { MODULES, ROUTES, defaultDateFormat } from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import ActivateDeactivateModal from './ActivateDeactivateModal';
import AddEditMemberModal from './AddEditMemberModal';
import { DELETE_USER } from './graphql/Mutations';
import { USERS } from './graphql/Queries';

const Members = () => {
  const initialUserFilter = {
    filters: { skip: 0, limit: 10 },
    sort: { sortOn: 'createdAt', sortBy: 'DESC' },
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const navigate = useNavigate();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [userFilter, setUserFilter] = useState(initialUserFilter);

  const [getUsersList, { loading, data }] = useLazyQuery(USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.users?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    getUsersList({ variables: userFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setSortedInfo({});
    setUserFilter(initialUserFilter);
    setPaginationProp(initialPaginationValue);
    getUsersList({ variables: { ...initialUserFilter } });
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      getUsersList({
        variables: {
          ...userFilter,
          filters: {
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setUserFilter({
        ...userFilter,
        filters: { skip, limit: pagination.pageSize },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      getUsersList({
        variables: {
          ...userFilter,
          filters: {
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  const handleChange = (e, checked, record) => {
    e?.stopPropagation();
    setSelectedRecord(record);
    setShowChangeStatusModal(true);
  };
  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, _, index) => (
        <div>{userFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'lastName' && sortedInfo?.order,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Access Level',
      dataIndex: 'role',
      key: 'role',
      render: (role) => capitalize(role),
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (date) => dayjs(date).format(defaultDateFormat),
    },
    {
      align: 'center',
      render: (id, record) => (
        <div className="d-flex align-center">
          <Tooltip title={record?.isActive ? 'Deactivate' : 'Activate'}>
            <Switch
              className="mr-10"
              checked={record?.isActive}
              onChange={(checked, e) => {
                handleChange(e, checked, record);
              }}
            />
          </Tooltip>
          <EditIcon
            className="mr-10 pointer"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRecord(record);
              setIsModalOpen(true);
            }}
          />
          <DeleteIcon
            className="pointer"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRecord(record);
              setShowDeleteModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const onSearchChange = async (value) => {
    setUserFilter({ ...userFilter, skip: 0, search: value });
    getUsersList({
      variables: {
        ...userFilter,
        filters: {
          skip: 0,
          search: value,
        },
      },
    });
  };

  return (
    <div className="categories-module">
      <Portal portalId="header-wrapper">
        <h1 className="m-0 font-600">{MODULES.MEMBERS}</h1>
      </Portal>
      <Row className="mb-24" justify="space-between">
        <Col>
          <SearchComponent
            id="search-container-id"
            name="member"
            getData={onSearchChange}
            className="category-search search-component"
          />
        </Col>
        <Col>
          <Button
            type="primary"
            className="add-new-btn"
            onClick={() => setIsModalOpen(true)}
          >
            Add New
          </Button>
        </Col>
      </Row>
      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.users?.users}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.MEMBERS}/${record?.id}`);
          },
        })}
        rowClassName={(record) =>
          !record?.isActive ? 'inactive-row pointer' : 'pointer'
        }
      />
      {isModalOpen && (
        <AddEditMemberModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          isModalOpen={showDeleteModal}
          setIsModalOpen={setShowDeleteModal}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          mutation={DELETE_USER}
          module="member"
        />
      )}
      {showChangeStatusModal && (
        <ActivateDeactivateModal
          isModalOpen={showChangeStatusModal}
          setIsModalOpen={setShowChangeStatusModal}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
    </div>
  );
};
export default Members;
