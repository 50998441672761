import { useLazyQuery } from '@apollo/client';
import { Button, Image, Tag } from 'antd';
import dayjs from 'dayjs';
import { capitalize, map, slice, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon, PdfIcon } from '../../../../assets/svg';
import {
  ROUTES,
  STATUS_COLORS,
  defaultDateFormat,
} from '../../../../common/constants';
import TableComponent from '../../../../components/CommonTable';
import DeleteConfirmModal from '../../../../components/DeleteConfirmModal';
import Portal from '../../../../components/Portal';
import SearchComponent from '../../../../components/SearchComponent';
import { DELETE_PRODUCT } from '../../graphql/Mutations';
import { GET_PRODUCTS } from '../../graphql/Queries';
import AddEditProducts from './AddEditProducts';
import ProductImageModal from './ProductImageModal';

const CategoryProducts = ({ id }) => {
  const navigate = useNavigate();
  const initialProductsFilter = {
    skip: 0,
    limit: 10,
    categoryId: id,
  };

  const initialProductsSort = {
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [productsFilter, setProductsFilter] = useState(initialProductsFilter);
  const [productsSort, setProductsSort] = useState(initialProductsSort);
  const [search, setSearch] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [remainingImages, setRemainingImages] = useState([]);

  const [fetchProducts, { loading, data, refetch }] = useLazyQuery(
    GET_PRODUCTS,
    {
      variables: {
        filters: { ...productsFilter, search, categoryId: id },
        sort: productsSort,
      },
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.productsAdmin?.count,
        };
        setPaginationProp(pagination);
      },
      onError() { },
    },
  );

  useEffect(() => {
    if (id) fetchProducts();
  }, [id]);

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, datas, index) => (
        <div>{productsFilter?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Product ID',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    },
    {
      title: 'Range',
      dataIndex: 'range',
      key: 'range',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'range' && sortedInfo?.order,
    },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      key: 'attachments',
      render: (attachments, record) => {
        if (record?.attachmentType === 'IMAGE') {
          return (
            <div
              className="attachments-div"
              onClick={(e) => {
                e?.stopPropagation();
              }}
            >
              <Image.PreviewGroup>
                {map(slice(attachments, 0, 3), (attachment) => (
                  <div className="product-image">
                    <Image
                      className="mr-8"
                      src={attachment?.url}
                      alt="img"
                      key={attachment?.key}
                      height={32}
                      width={32}
                    />
                  </div>
                ))}
                {attachments?.length > 3 && (
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageModal(true);
                      setRemainingImages(attachments);
                    }}
                  >
                    + {attachments?.length - 3}
                  </Button>
                )}
              </Image.PreviewGroup>
            </div>
          );
        }
        return (
          <div className="d-flex">
            {map(attachments, (attachment) => (
              <PdfIcon
                className="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  // eslint-disable-next-line no-undef
                  window.open(attachment?.url, '_blank');
                }}
                alt="pdf"
                key={attachment?.key}
                height={32}
                width={32}
              />
            ))}
          </div>
        );
      },
    },
    {
      title: 'Category Name',
      dataIndex: 'category',
      key: 'category',
      render: (category) => category?.name,
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (value) => dayjs(value).format(defaultDateFormat),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => (
        <Tag className={`products-tags ${STATUS_COLORS[status]}`}>
          {capitalize(status)}
        </Tag>
      ),
    },
    {
      align: 'center',
      render: (value, record) => (
        <div>
          <EditIcon
            className="mr-10 pointer"
            onClick={(e) => {
              e.stopPropagation();
              setOpenModal(true);
              setEditData(record);
            }}
          />
          <DeleteIcon
            className="pointer"
            onClick={(e) => {
              e.stopPropagation();
              setShowDeleteModal(true);
              setEditData(record);
            }}
          />
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setProductsSort({
        sortOn: sorter.field,
        sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      });
      setProductsFilter({
        ...productsFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchProducts({
        variables: {
          filters: {
            ...productsFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setProductsSort({
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      setProductsFilter({
        ...productsFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchProducts({
        variables: {
          filters: {
            ...productsFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  const onSearchChange = async (value) => {
    setSearch(trim(value));
    setProductsFilter({ ...productsFilter, skip: 0, search: value });
    fetchProducts({
      variables: {
        sort: productsSort,
        filters: {
          ...productsFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  return (
    <div className="product-module">
      {openModal && (
        <AddEditProducts
          openModal={openModal}
          setOpenModal={setOpenModal}
          editData={editData}
          refetch={refetch}
          setEditData={setEditData}
          categoryId={id}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          isModalOpen={showDeleteModal}
          setIsModalOpen={setShowDeleteModal}
          refetch={refetch}
          selectedRecord={editData}
          setSelectedRecord={setEditData}
          mutation={DELETE_PRODUCT}
          module="product"
        />
      )}
      {imageModal && (
        <ProductImageModal
          open={imageModal}
          setOpen={setImageModal}
          images={remainingImages}
        />
      )}
      <Portal portalId="add-category-details">
        <div className="d-flex">
          <SearchComponent
            name="products"
            getData={onSearchChange}
            className="category-search search-component mr-16"
          />
          <Button
            type="primary"
            className="add-new-btn"
            onClick={() => setOpenModal(true)}
          >
            Add New
          </Button>
        </div>
      </Portal>
      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.productsAdmin?.products ?? []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.CATEGORIES}/${id}/products/${record?.id}`);
          },
        })}
        scroll={{ y: 450 }}
        rowClassName="pointer"
      />
    </div>
  );
};

export default CategoryProducts;
