import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { normalizeNegativeNumber } from '../../../../common/utils';
import BarcodeLabel from './BarcodeLabel';
import LabelPrint from './LabelPrint';

const printTypeOptions = [
  { label: 'LD Print', value: 'LD_PRINT' },
  { label: 'Box Label', value: 'BOX_LABEL' },
];

const PrintBarcodeModal = ({
  openModal,
  setOpenModal,
  barCode = '',
  sku = '',
  color = '',
  productData,
}) => {
  const barcodeRef = useRef();
  const labelRef = useRef();

  const [formData, setFormData] = useState({
    type: null,
    quantity: '',
    companyName: '',
    range: '',
    color,
    sku,
    manufacturing: dayjs(),
  });

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (productData) {
      const prepareValues = {
        ...formData,
        range: productData?.range ?? '',
      };
      setFormData(prepareValues);
    }
  }, [productData]);

  const handleCancel = () => {
    setOpenModal(false);
  };

  const handleFormChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateFields = () => {
    const newErrors = {};

    if (!formData.type) newErrors.type = 'Type is required';
    if (!formData.quantity) newErrors.quantity = 'Quantity is required';
    if (formData.type && !formData.companyName)
      newErrors.companyName = 'Company Name is required';
    if (formData.type === 'BOX_LABEL' && !formData.manufacturing)
      newErrors.manufacturing = 'Manufacturing date is required';

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  };

  useEffect(() => {
    validateFields();
    if (formData?.type) {
      // eslint-disable-next-line no-undef
      const companyName = localStorage.getItem(
        formData?.type === 'BOX_LABEL'
          ? 'BOX_LABEL_COMPANY'
          : 'LD_PRINT_COMPANY',
      );
      setFormData({ ...formData, companyName });
    }
  }, [formData]);

  return (
    <Modal
      centered
      open={openModal}
      onCancel={handleCancel}
      title={<h3 className="m-0 mb-10">Print Barcode</h3>}
      footer={null}
      width={576}
    >
      <div>
        <Form layout="vertical" className="category-forms mt-16">
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item label="Type" required>
                <Select
                  placeholder="Select print type"
                  options={printTypeOptions}
                  onChange={(value) => handleFormChange('type', value)}
                  value={formData.type}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Quantity"
                required
                normalize={normalizeNegativeNumber}
              >
                <Input
                  type="number"
                  placeholder="Enter printing quantity here"
                  className="number-input"
                  value={formData.quantity}
                  onChange={(e) => {
                    handleFormChange(
                      'quantity',
                      parseInt(e.target.value > 100 ? 100 : e.target.value, 10),
                    );
                  }}
                />
              </Form.Item>
            </Col>
            {formData?.type && (
              <>
                <Col span={24}>
                  <Form.Item label="Company Name" required>
                    <Input
                      placeholder="Enter company name"
                      value={formData.companyName}
                      onChange={(e) => {
                        handleFormChange('companyName', e.target.value);
                        // eslint-disable-next-line no-undef
                        localStorage.setItem(
                          formData?.type === 'BOX_LABEL'
                            ? 'BOX_LABEL_COMPANY'
                            : 'LD_PRINT_COMPANY',
                          e.target.value,
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Range">
                    <Input disabled value={formData.range} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="SKU">
                    <Input disabled value={formData.sku} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Color">
                    <Input disabled value={formData.color} />
                  </Form.Item>
                </Col>
                {formData?.type === 'BOX_LABEL' && (
                  <Col span={12}>
                    <Form.Item label="Manufacturing" required>
                      <DatePicker
                        placeholder="Select manufacturing"
                        value={formData.manufacturing}
                        onChange={(value) => {
                          handleFormChange('manufacturing', value);
                        }}
                        picker="month"
                        format="MM/YYYY"
                      />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Form>
        {formData?.type && (
          <div ref={formData?.type === 'barcode' ? barcodeRef : labelRef}>
            {formData?.type === 'LD_PRINT' ? (
              <BarcodeLabel
                sku={formData?.sku}
                range={formData?.range}
                color={formData?.color}
                brand={formData?.companyName}
                barcodeValue={barCode}
              />
            ) : (
              <LabelPrint
                productName={productData?.name}
                sku={formData?.sku}
                range={formData?.range}
                color={formData?.color}
                brand={formData?.companyName}
                barcodeValue={barCode}
                manufacturing={
                  formData?.manufacturing
                    ? dayjs(formData?.manufacturing).format('YY')
                    : ''
                }
                month={
                  formData?.manufacturing
                    ? dayjs(formData?.manufacturing).format('MMM')
                    : ''
                }
              />
            )}
          </div>
        )}
        {!formData.type && (
          <div>
            <p>Preview</p>
            <div className="preview-empty">
              <p>Once selected preview will be displayed here</p>
            </div>
          </div>
        )}

        <ReactToPrint
          trigger={() => (
            <Button
              className="full-width font-600 submit-btn mt-32"
              type="primary"
              disabled={!isFormValid}
            >
              Print {formData.type === 'BOX_LABEL' ? 'Labels' : 'Barcodes'}
            </Button>
          )}
          content={() => {
            // eslint-disable-next-line no-undef
            const content = document.createElement('div');
            const printRef =
              formData.type === 'barcode' ? barcodeRef : labelRef;
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < formData.quantity; i++) {
              const label = printRef.current.cloneNode(true);
              content.appendChild(label);
            }
            return content;
          }}
        />
      </div>
    </Modal>
  );
};

export default PrintBarcodeModal;
