import { useLazyQuery } from '@apollo/client';
import { Button, Col, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon } from '../../assets/svg';
import { MODULES, ROUTES, defaultDateFormat } from '../../common/constants';
import TableComponent from '../../components/CommonTable';
import DeleteConfirmModal from '../../components/DeleteConfirmModal';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import AddEditContractorModal from './AddEditContractorModal';
import { DELETE_CONTRACTOR } from './graphql/Mutations';
import { GET_CONTRACTORS } from './graphql/Queries';

const Contractor = () => {
  const initialContractorFilter = {
    filters: { skip: 0, limit: 10 },
    sort: { sortOn: 'createdAt', sortBy: 'DESC' },
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const navigate = useNavigate();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [contractorFilter, setContractorFilter] = useState(
    initialContractorFilter,
  );

  const [listContractors, { loading, data }] = useLazyQuery(GET_CONTRACTORS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.contractors?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    listContractors({ variables: contractorFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    setSortedInfo({});
    setContractorFilter(initialContractorFilter);
    setPaginationProp(initialPaginationValue);
    listContractors({ variables: { ...initialContractorFilter } });
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);

    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setContractorFilter({
        ...contractorFilter,
        sort: {
          sortOn: sorter.field,
          sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      listContractors({
        variables: {
          ...contractorFilter,
          filters: {
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setContractorFilter({
        ...contractorFilter,
        filters: { skip, limit: pagination.pageSize },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      });
      listContractors({
        variables: {
          ...contractorFilter,
          filters: {
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'createdAt', sortBy: 'DESC' },
        },
      });
    }
  };

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, _, index) => (
        <div>{contractorFilter?.filters?.skip + index + 1}</div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'code',
      key: 'code',
      width: '15%',
      align: 'center',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Phone Number',
      key: 'phoneNo',
      dataIndex: 'phoneNo',
      render: (record) => <div>+91 {record}</div>,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (date) => dayjs(date).format(defaultDateFormat),
    },
    {
      align: 'center',
      render: (id, record) => (
        <>
          <Tooltip title="Edit Contractor">
            <EditIcon
              className="mr-10 pointer"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setIsModalOpen(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete Contractor">
            <DeleteIcon
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setShowDeleteModal(true);
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const onSearchChange = async (value) => {
    setContractorFilter({
      ...contractorFilter,
      filters: {
        ...contractorFilter?.filters,
        skip: 0,
        search: value,
      },
    });
    listContractors({
      variables: {
        ...contractorFilter,
        filters: {
          ...contractorFilter?.filters,
          skip: 0,
          search: value,
        },
      },
    });
  };

  return (
    <div>
      <Portal portalId="header-wrapper">
        <h1 className="m-0 font-600">{MODULES.CONTRACTOR}</h1>
      </Portal>
      <Row className="mb-24" justify="space-between">
        <Col>
          <SearchComponent
            id="search-container-id"
            name="contractors"
            getData={onSearchChange}
            className="category-search search-component"
          />
        </Col>
        <Col>
          <Button
            type="primary"
            className="add-new-btn"
            onClick={() => {
              setSelectedRecord();
              setIsModalOpen(true);
            }}
          >
            ADD NEW
          </Button>
        </Col>
      </Row>
      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.contractors?.contractors}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.CONTRACTOR}/${record?.id}`);
          },
        })}
        rowClassName="pointer"
        scroll={{ y: 472 }}
      />
      {isModalOpen && (
        <AddEditContractorModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          isModalOpen={showDeleteModal}
          setIsModalOpen={setShowDeleteModal}
          refetch={refetchUserDetails}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          mutation={DELETE_CONTRACTOR}
          module="contractor"
        />
      )}
    </div>
  );
};

export default Contractor;
