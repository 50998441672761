import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import LoaderComponent from '../../components/LoaderComponent';
import { LOGOUT_USER } from './graphql/Queries';

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const [logout, { loading }] = useMutation(LOGOUT_USER, {
    onCompleted() {
      dispatch({ type: 'LOGOUT' });
      // eslint-disable-next-line no-undef
      window.location = '/login';
    },
    onError() {
      dispatch({ type: 'LOGOUT' });
      // eslint-disable-next-line no-undef
      window.location = '/login';
    },
  });

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default Logout;
