import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { upperCase } from 'lodash';
import React, { useState } from 'react';
import { REGEX } from '../../common/constants';
import { ADD_CONTRACTOR, UPDATE_CONTRACTOR } from './graphql/Mutations';

function AddEditContractorModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  selectedRecord,
  setSelectedRecord,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [createUpdateContractor] = useMutation(
    selectedRecord ? UPDATE_CONTRACTOR : ADD_CONTRACTOR,
    {
      onError: () => {
        setLoading(false);
      },
    },
  );

  const onFinish = async (values) => {
    setLoading(true);
    createUpdateContractor({
      variables: {
        input: { ...values, countryCode: '+91' },
        where: {
          id: selectedRecord?.id,
        },
      },
    }).then((res) => {
      if (res?.data) {
        setLoading(false);
        setIsModalOpen(false);
        setSelectedRecord(null);
        refetch();
      }
    });
  };

  return (
    <div id="add-edit-categories-modal">
      <Modal
        centered
        title={
          <h3 className="m-0 mb-10">
            {upperCase(`${selectedRecord ? 'Edit' : 'Add New'} Contractor`)}
          </h3>
        }
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        width={576}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            firstName: selectedRecord?.firstName,
            lastName: selectedRecord?.lastName,
            phoneNo: selectedRecord?.phoneNo,
          }}
          className="category-forms mt-16"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                whitespace: true,
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                whitespace: true,
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>

          <Form.Item label="Phone Number">
            <Row gutter={10}>
              <Col span={4}>
                <Select value="+91">
                  <Select.Option value="+91">+91</Select.Option>
                </Select>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="phoneNo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input phone number!',
                    },
                    {
                      pattern: REGEX.PHONE,
                      message: 'Enter Valid Phone Number',
                    },
                  ]}
                >
                  <Input placeholder="Enter Phone Number" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="full-width font-600 submit-btn"
            disabled={loading}
            loading={loading}
          >
            {upperCase(`${selectedRecord ? 'Update' : 'Add'} Contractor`)}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default AddEditContractorModal;
