import { gql } from '@apollo/client';

export const LOGIN_ADMIN = gql`
  mutation loginAdmin($input: LoginInput!) {
    loginAdmin(input: $input) {
      accessToken
      message
      refreshToken
      user {
        email
        firstName
        id
        isActive
        lastName
        role
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation emailPasswordSignUp($data: EmailPasswordSignUpData!) {
    emailPasswordSignUp(data: $data) {
      message
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      message
    }
  }
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($input: SetPasswordInput!) {
    setPassword(input: $input) {
      message
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation emailPasswordVerifyResetToken($token: String!) {
    emailPasswordVerifyResetToken(data: { token: $token }) {
      message
      data {
        isValidToken
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
    updateCurrentUser(data: $data) {
      message
      data {
        id
        email
        profileImage
        name
        firstName
        lastName
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation NewAccessToken($input: NewAccessTokenInput!) {
    newAccessToken(input: $input) {
      accessToken
      refreshToken
      user {
        id
        firstName
        lastName
        email
        role
        isActive
        createdAt
      }
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      message
    }
  }
`;
