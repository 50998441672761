import { gql } from '@apollo/client';

export const GET_CONTRACTORS = gql`
  query Contractors(
    $filters: ContractorsFilterInput
    $sort: ContractorsSortInput
  ) {
    contractors(filters: $filters, sort: $sort) {
      count
      contractors {
        id
        firstName
        lastName
        phoneNo
        countryCode
        createdAt
        code
      }
    }
  }
`;

export const GET_CONTRACTOR = gql`
  query Contractor($where: UniqueWhereInput!) {
    contractor(where: $where) {
      code
      countryCode
      createdAt
      firstName
      id
      lastName
      phoneNo
    }
  }
`;
export const GET_CONTACTS = gql`
  query Contracts($filters: ContractsFilterInput!, $sort: ContractsSortInput) {
    contracts(filters: $filters, sort: $sort) {
      count
      contracts {
        code
        createdAt
        id
        isEditable
        isLegacy
        outPdfUrl {
          key
          url
        }
        inPdfUrl {
          key
          url
        }
      }
    }
  }
`;

export const CONTRACT_ITEM = gql`
  query ContractItems(
    $filters: ContractItemFilters!
    $sort: ContractItemSortInput
  ) {
    contractItems(filters: $filters, sort: $sort) {
      count
      contractItems {
        id
        productName
        quantity
        noOfBindi
        noOfPattas
        packaging
        color
        createdAt
        updatedAt
        totalOutQty
        outUnit {
          gross
          dozen
          pieces
        }
        totalInQty
        inUnit {
          gross
          dozen
          pieces
        }
        pricePerGross
        totalAmount
        wastagePercentage
      }
    }
  }
`;
