import { Button, Modal } from 'antd';
import React from 'react';
import Barcode from 'react-jsbarcode';
import { copyToClipboard } from '../../../../common/utils';

const ViewBarcodeModal = ({ openModal, setOpenModal, barCode = '' }) => {
  const handleCancel = () => {
    setOpenModal(false);
  };
  return (
    <Modal
      centered
      open={openModal}
      onCancel={handleCancel}
      title={<h3 className="m-0 mb-10">Product Variation Barcode</h3>}
      footer={null}
      width={550}
    >
      <div>
        <Barcode
          value={barCode}
          className="mb-20"
          options={{ format: 'code128' }}
        />
        <Button
          className="full-width font-600 submit-btn"
          type="primary"
          onClick={() => copyToClipboard(barCode)}
        >
          Copy Barcode
        </Button>
      </div>
    </Modal>
  );
};

export default ViewBarcodeModal;
