import { gql } from '@apollo/client';

export const USERS = gql`
  query Users($filters: UsersFilterInput, $sort: UsersSortInput) {
    users(filters: $filters, sort: $sort) {
      count
      users {
        email
        firstName
        id
        isActive
        lastName
        profilePicture
        role
        createdAt
      }
    }
  }
`;
export const GET_USER = gql`
  query User($where: UniqueWhereInput!) {
    user(where: $where) {
      createdAt
      email
      id
      firstName
      isActive
      lastName
      profilePicture
      role
    }
  }
`;

export const USER_SESSION = gql`
  query UserSessions(
    $filters: UserSessionsFilterInput!
    $sort: UserSessionsSortInput
  ) {
    userSessions(filters: $filters, sort: $sort) {
      userSessions {
        browser
        createdAt
        ip
        os
      }
      count
    }
  }
`;
