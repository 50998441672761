import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DatePicker, Radio, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { clone, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditIcon, ScannerIcon } from '../../../../assets/svg';
import {
  INVENTORY_TYPE,
  ROUTES,
  defaultDateFormat,
} from '../../../../common/constants';
import TableComponent from '../../../../components/CommonTable';
import Portal from '../../../../components/Portal';
import SearchComponent from '../../../../components/SearchComponent';
import { START_INVENTORY_OUT_SESSION } from '../../graphql/Mutations';
import { INVENTORIES } from '../../graphql/Queries';
import AddEditInventory from './AddEditInventory';

const activityFilter = [
  { text: ' In', value: 'IN' },
  { text: 'Out', value: 'OUT' },
];
const { RangePicker } = DatePicker;

const CategoryInventories = ({ id }) => {
  const navigate = useNavigate();
  const initialInventoriesFilter = {
    skip: 0,
    limit: 10,
    categoryId: id,
  };

  const initialInventoriesSort = {
    sortOn: 'actionDate',
    sortBy: 'DESC',
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [inventoriesFilter, setInventoriesFilter] = useState(
    initialInventoriesFilter,
  );
  const [inventoriesSort, setInventoriesSort] = useState(
    initialInventoriesSort,
  );
  const [sortedInfo, setSortedInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedDate, setSelectedDate] = useState([]);
  const [isDateFilterSelected, setIsDateFilterSelected] = useState(false);
  const [fetchInventories, { loading, data, refetch }] = useLazyQuery(
    INVENTORIES,
    {
      variables: {
        filters: { ...inventoriesFilter, categoryId: id },
        sort: inventoriesSort,
      },
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.inventories?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  const [
    startInventoryOutSession,
    { loading: startSessionLoading },
  ] = useMutation(START_INVENTORY_OUT_SESSION, {
    onError() {},
  });

  useEffect(() => {
    if (id) fetchInventories();
  }, [id]);

  const handleActivityFilter = (selectedKeys, confirmFilter) => {
    confirmFilter();
    setInventoriesFilter({
      ...inventoriesFilter,
      skip: 0,
      categoryId: id,
      type: selectedKeys,
    });
    fetchInventories({
      filters: {
        ...inventoriesFilter,
        skip: 0,
        categoryId: id,
        type: selectedKeys,
      },
      sort: inventoriesSort,
    });
  };
  const handleOk = (action, clearFilters, confirmFilter) => {
    if (action !== 'reset') {
      setIsDateFilterSelected(true);
      confirmFilter();
    } else {
      setIsDateFilterSelected(false);
      clearFilters();
      confirmFilter();
    }
    setInventoriesFilter({
      ...inventoriesFilter,
      skip: 0,
      categoryId: id,
      dateFilter:
        action !== 'reset'
          ? {
              start: dayjs(selectedDate?.[0]).toISOString(),
              end: dayjs(selectedDate?.[1]).toISOString(),
            }
          : undefined,
    });
    fetchInventories({
      filters: {
        ...inventoriesFilter,
        skip: 0,
        categoryId: id,
        dateFilter:
          action !== 'reset'
            ? {
                start: dayjs(selectedDate?.[0]).toISOString(),
                end: dayjs(selectedDate?.[1]).toISOString(),
              }
            : undefined,
      },
      sort: inventoriesSort,
    });
  };

  function handleActivityFilterReset(clearFilters, confirm) {
    clearFilters();
    confirm();
    const tempFilter = clone(inventoriesFilter);
    setInventoriesFilter({ ...tempFilter, skip: 0, type: null });
    fetchInventories({
      variables: {
        filters: { ...tempFilter, skip: 0, type: null },
        sort: inventoriesSort,
      },
    });
  }

  function filterData(confirmFilter) {
    confirmFilter();
    setInventoriesFilter({
      ...inventoriesFilter,
      skip: 0,
      type: null,
    });
    fetchInventories({
      variables: {
        filters: { ...inventoriesFilter, skip: 0, type: null },
        sort: inventoriesSort,
      },
    });
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm: confirmFilter,
        clearFilters,
      }) => (
        <div className="d-flex flex-vertical activity-filter-wrapper">
          <Radio.Group
            onChange={(e) => setSelectedKeys(e?.target?.value ?? '')}
            value={selectedKeys}
          >
            <Space direction="vertical">
              {activityFilter?.map((filter) => (
                <Radio key={filter?.value} value={filter?.value}>
                  {filter?.text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <div className="activity-filter-btns d-flex">
            <Button
              id={`btn-${dataIndex}-search`}
              type="primary"
              onClick={() => {
                if (selectedKeys) {
                  handleActivityFilter(selectedKeys, confirmFilter, dataIndex);
                } else {
                  filterData(confirmFilter);
                }
              }}
              size="small"
              className="mr-12"
            >
              Ok
            </Button>
            <Button
              id={`btn-${dataIndex}-reset`}
              onClick={() =>
                handleActivityFilterReset(clearFilters, confirmFilter)
              }
              size="small"
            >
              Reset
            </Button>
          </div>
        </div>
      ),
    };
  }
  function getDateFilter(dataIndex) {
    return {
      filterDropdown: ({ confirm: confirmFilter, clearFilters }) => (
        <div className="d-flex flex-vertical activity-filter-wrapper">
          <RangePicker
            format="DD/MM/YYYY"
            allowClear={false}
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e);
            }}
          />
          <div className="activity-filter-btns d-flex">
            <Button
              id={`btn-${dataIndex}-search`}
              type="primary"
              onClick={() => {
                handleOk('ok', clearFilters, confirmFilter);
              }}
              size="small"
              className="mr-12"
            >
              Ok
            </Button>
            <Button
              id={`btn-${dataIndex}-reset`}
              onClick={() => {
                setSelectedDate([]);
                handleOk('reset', clearFilters, confirmFilter);
              }}
              size="small"
            >
              Reset
            </Button>
          </div>
        </div>
      ),
    };
  }

  const columns = [
    {
      title: '#',
      key: '#',
      width: '10%',
      align: 'center',
      render: (record, datas, index) => (
        <div>{inventoriesFilter?.skip + index + 1}</div>
      ),
    },
    {
      title: 'Product Name',
      dataIndex: ['product', 'name'],
      key: 'product_name',
    },
    {
      title: 'Quantity (Gross Unit)',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Activity',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      filters: activityFilter,
      ...getColumnSearchProps('type'),
      render: (value, record) => {
        const { actionBy } = record;

        const { firstName, lastName, email } = actionBy;
        const tooltipContent = `Added By: ${
          firstName && lastName ? `${firstName} ${lastName}` : email
        }`;

        return (
          <Tooltip title={tooltipContent} className="pointer">
            <span>{INVENTORY_TYPE[value]}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Variations',
      dataIndex: ['productVariation', 'code'],
      key: 'variation',
      align: 'center',
    },
    {
      title: 'Action Date',
      dataIndex: 'actionDate',
      key: 'actionDate',
      align: 'center',
      sorter: true,
      filteredValue: isDateFilterSelected ? 'actionDate' : null,
      sortOrder: sortedInfo?.columnKey === 'actionDate' && sortedInfo?.order,
      ...getDateFilter('type'),
      render: (value) => dayjs(value).format(defaultDateFormat),
    },
    {
      align: 'center',
      render: (value, record) => (
        <div>
          {record.type !== 'OUT' ? (
            <EditIcon
              className="mr-10 pointer"
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
                setEditData(record);
              }}
            />
          ) : (
            <EditIcon className="mr-10 pointer disabled" />
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setInventoriesSort({
        sortOn: sorter.field,
        sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      });
      setInventoriesFilter({
        ...inventoriesFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchInventories({
        variables: {
          filters: {
            ...inventoriesFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: {
            sortOn: sorter.field,
            sortBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setInventoriesSort({
        sortOn: 'actionDate',
        sortBy: 'DESC',
      });
      setInventoriesFilter({
        ...inventoriesFilter,
        skip,
        limit: pagination.pageSize,
      });
      fetchInventories({
        variables: {
          filters: {
            ...inventoriesFilter,
            skip,
            limit: pagination.pageSize,
          },
          sort: { sortOn: 'actionDate', sortBy: 'DESC' },
        },
      });
    }
  };

  const onSearchChange = async (value) => {
    setInventoriesFilter({ ...inventoriesFilter, skip: 0, search: value });
    fetchInventories({
      variables: {
        sort: inventoriesSort,
        filters: {
          ...inventoriesFilter,
          skip: 0,
          search: trim(value),
        },
      },
    });
  };

  const handleInventoryOut = () => {
    startInventoryOutSession().then((res) => {
      if (res?.data?.startInventoryOutSession?.sessionId) {
        navigate(
          `${ROUTES.CATEGORIES}/${res?.data?.startInventoryOutSession?.sessionId}/inventory-out`,
        );
      }
    });
  };

  return (
    <div className="product-module">
      {openModal && (
        <AddEditInventory
          openModal={openModal}
          setOpenModal={setOpenModal}
          editData={editData}
          refetch={refetch}
          setEditData={setEditData}
          categoryId={id}
        />
      )}
      <Portal portalId="add-category-details">
        <div className="d-flex">
          <SearchComponent
            name="inventory"
            getData={onSearchChange}
            className="search-component category-search mr-16"
          />
          <Button
            className="mr-16 mb-0 scanner-btn"
            icon={<ScannerIcon />}
            onClick={() => {
              handleInventoryOut();
            }}
            loading={startSessionLoading}
          />
          <Button
            type="primary"
            className="add-new-btn"
            onClick={() => setOpenModal(true)}
          >
            Add New
          </Button>
        </div>
      </Portal>
      <TableComponent
        loadingData={loading}
        columns={columns}
        data={data?.inventories?.inventories ?? []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj.id}
        tableClassName="products-table"
        scroll={{ y: 458 }}
      />
    </div>
  );
};

export default CategoryInventories;
