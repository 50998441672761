import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
  Upload,
  message,
} from 'antd';
import {
  filter,
  find,
  findIndex,
  isEmpty,
  isObject,
  map,
  nth,
  omit,
  pick,
  trim,
  upperCase,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CloseIcon,
  Delete,
  ImageIcon,
  PdfIcon,
  UploadIcon,
} from '../../../../assets/svg';
import { STATUS } from '../../../../common/constants';
import {
  fileUpload,
  getBase64File,
  normalizeNegativeNumber,
} from '../../../../common/utils';
import { CREATE_PRODUCT, UPDATE_PRODUCT } from '../../graphql/Mutations';
import {
  GET_CATEGORY,
  GET_CATEGORY_VARIATIONS,
  GET_PRODUCT_ATTACHMENT_UPLOAD_URL,
} from '../../graphql/Queries';

const AddEditProducts = ({
  openModal,
  setOpenModal,
  editData,
  refetch,
  setEditData,
  categoryId,
}) => {
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);
  const [pdf, setPdf] = useState({});
  const [attachmentType, setAttachmentType] = useState('IMAGE');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [attachmentError, setAttachmentError] = useState(false);
  const [variationWise, setVariationWise] = useState(false);
  const [isPackagingPresent, setIsPackagingPresent] = useState(false);
  const [variationWiseDeduction, setVariationWiseDeduction] = useState([]);

  const [fetchCategory, { loading: fetchingCategories }] = useLazyQuery(
    GET_CATEGORY,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id: categoryId,
        },
      },
      onCompleted(res) {
        form.setFieldValue('category', res?.category?.name);
      },
      onError() { },
    },
  );

  const [fetchCategoryVariations, { loading }] = useLazyQuery(
    GET_CATEGORY_VARIATIONS,
    {
      fetchPolicy: 'network-only',
      variables: {
        filters: { bypassPagination: true, categoryId },
        sort: { sortOn: 'createdAt', sortBy: 'DESC' },
      },
      onCompleted(res) {
        const findPackagingIndex = findIndex(
          res?.categoryVariations?.categoryVariations,
          (item) => item?.key === 'PACKAGING',
        );
        if (findPackagingIndex !== -1) {
          if (!editData) setVariationWise(true);
          setIsPackagingPresent(true);
          const variationDeduction = map(
            res?.categoryVariations?.categoryVariations?.[findPackagingIndex]
              ?.values,
            (item) => omit(item, '__typename'),
          );
          setVariationWiseDeduction(variationDeduction);
          form?.setFieldsValue({
            deductQty: {
              variationWiseDeduction: map(variationDeduction, (item) => ({
                key: item?.name,
                qty:
                  editData && editData?.deductQty?.variationWiseDeduction
                    ? String(
                      find(
                        editData?.deductQty?.variationWiseDeduction,
                        (deductionItem) => deductionItem?.key === item?.key,
                      )?.qty,
                    )
                    : null,
              })),
            },
          });
        } else {
          if (!editData) setVariationWise(false);
          setIsPackagingPresent(false);
        }
      },
      onError() { },
    },
  );

  useEffect(() => {
    if (categoryId) {
      fetchCategory();
      fetchCategoryVariations();
    }
  }, [categoryId]);

  const [getProductAttachmentUploadSignedUrl] = useLazyQuery(
    GET_PRODUCT_ATTACHMENT_UPLOAD_URL,
    {
      fetchPolicy: 'network-only',
      onError() { },
    },
  );

  const [createUpdateProduct] = useMutation(
    editData ? UPDATE_PRODUCT : CREATE_PRODUCT,
    {
      onError() {
        setButtonLoading(false);
      },
    },
  );

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        ...editData,
        deductQty: {
          ...editData?.deductQty,
          simpleDeduction: editData?.deductQty?.simpleDeduction
            ? String(editData?.deductQty?.simpleDeduction)
            : null,
        },
      });
      setVariationWise(editData?.deductQty?.variationWise);
      setAttachmentType(editData?.attachmentType);
      if (editData?.attachmentType === 'IMAGE') {
        setImages(editData?.attachments);
        form.setFieldValue('image', editData?.attachments);
      } else {
        setPdf({
          data: {
            name: editData?.attachments?.[0]?.key?.split('/')?.[1],
            key: editData?.attachments?.[0]?.key,
          },
          url: editData?.attachments?.[0]?.url,
        });
      }
    }
  }, [editData]);

  const handleFinish = async (values) => {
    if (isEmpty(images) && isEmpty(pdf)) {
      setAttachmentError(true);
      return;
    }
    setAttachmentError(false);

    setButtonLoading(true);
    let isError = false;
    const payload = {
      ...values,
      categoryId,
      name: trim(values?.name),
      attachmentType,
      deductQty: {
        variationWise,
        simpleDeduction: values?.deductQty?.simpleDeduction
          ? Number(values?.deductQty?.simpleDeduction)
          : null,
        variationWiseDeduction: values?.deductQty?.variationWiseDeduction
          ? map(values?.deductQty?.variationWiseDeduction, (item) => ({
            qty: Number(item?.qty),
            key: find(
              variationWiseDeduction,
              (deductionItem) => deductionItem.name === item.key,
            )?.key,
          }))
          : null,
      },
    };

    if (!isEmpty(pdf)) {
      if (pdf?.data?.size) {
        const res = await getProductAttachmentUploadSignedUrl({
          variables: {
            input: {
              contentLength: pdf?.data?.size,
              fileName: pdf?.data?.name,
            },
          },
        });
        if (res?.data?.getProductAttachmentUploadSignedUrl?.signedUrl) {
          await fileUpload(
            res?.data?.getProductAttachmentUploadSignedUrl?.signedUrl,
            pdf?.data,
          );
          payload.attachments = [
            res?.data?.getProductAttachmentUploadSignedUrl?.key,
          ];
        }
      } else {
        payload.attachments = [pdf?.data?.key];
      }
    }
    async function uploadImages(imagesList, uploadedImageKeys = []) {
      if (imagesList.length === 0) {
        payload.attachments = uploadedImageKeys;
        return;
      }
      const image = imagesList[0];
      if (image?.data) {
        try {
          const signedUrlResponse = await getProductAttachmentUploadSignedUrl({
            variables: {
              input: {
                contentLength: image?.data?.originFileObj?.size,
                fileName: image?.data?.originFileObj?.name,
              },
            },
          });
          if (signedUrlResponse?.data) {
            isError = false;
            await fileUpload(
              signedUrlResponse?.data?.getProductAttachmentUploadSignedUrl
                ?.signedUrl,
              image?.data?.originFileObj,
            );
            const key =
              signedUrlResponse?.data?.getProductAttachmentUploadSignedUrl?.key;
            if (key) {
              uploadedImageKeys.push(key);
            }
          } else {
            isError = true;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error uploading image:', error);
          isError = true;
        }
      } else {
        uploadedImageKeys.push(image?.key);
      }
      await uploadImages(imagesList.slice(1), uploadedImageKeys);
    }
    if (!isEmpty(images)) {
      await uploadImages(images).catch((error) => {
        isError = true;
        return error;
      });
    }
    delete payload.image;
    if (isError) {
      setButtonLoading(false);
      return;
    }
    delete payload.category;
    if (editData) delete payload.categoryId;
    const res = await createUpdateProduct({
      variables: {
        input: payload,
        ...(editData && {
          where: {
            id: editData?.id,
          },
        }),
      },
    });
    if (res?.data) {
      setOpenModal(false);
      form?.resetFields();
      setPdf({});
      setImages([]);
      if (refetch) refetch();
      setEditData(null);
    }
    setButtonLoading(false);
  };

  const handleImageUploadChange = async ({ fileList }) => {
    if (isEmpty(fileList) && isEmpty(pdf)) {
      setAttachmentError(true);
    } else {
      setAttachmentError(false);
    }
    const newImageList = fileList.map((file) => {
      if (!isObject(file)) {
        return;
      }
      const fileType = file.type;
      if (!fileType.startsWith('image/')) {
        return;
      }
      const isJpgOrPng =
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg';
      if (!isJpgOrPng) {
        return;
      }
      const isLt5M = file?.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        return;
      }
      return new Promise((resolve) => {
        getBase64File(file.originFileObj, (imageUrl) => {
          resolve({ data: file, url: imageUrl });
        });
      });
    });
    Promise.all(newImageList.filter(Boolean)).then((urls) => {
      setImages([...images, ...urls]);
    });
    setAttachmentType('IMAGE');
  };

  return (
    <Modal
      open={openModal}
      title={
        <h2 className="m-0 mb-10">
          {upperCase(`${editData ? 'Edit' : 'Add'} Product`)}
        </h2>
      }
      centered
      onCancel={() => {
        setOpenModal(false);
        setEditData(null);
      }}
      footer={null}
      width={600}
    >
      <Form
        layout="vertical"
        className="category-forms mt-16"
        form={form}
        onFinish={handleFinish}
        initialValues={{ status: 'DRAFT' }}
        onFinishFailed={() => {
          if (isEmpty(images) && isEmpty(pdf)) {
            setAttachmentError(true);
          } else {
            setAttachmentError(false);
          }
        }}
      >
        <div className="product-form">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter name!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter product name" />
          </Form.Item>
          <Form.Item
            label="Range"
            name="range"
            rules={[
              {
                required: true,
                message: 'Please enter range!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter product range" />
          </Form.Item>
          <Form.Item label="Category" name="category">
            <Input placeholder="Enter category" disabled />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Please select status!' }]}
          >
            <Radio.Group>
              {map(
                pick(STATUS, ['PUBLISHED', 'UNPUBLISHED', 'DRAFT']),
                (status, key) => (
                  <Radio value={key} key={key}>
                    {status}
                  </Radio>
                ),
              )}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Attachments" className="attachments-form-item mb-0">
            <Row gutter={[16]}>
              {isEmpty(pdf) && (
                <Col span={isEmpty(images) && 12}>
                  <Form.Item
                    name="image"
                    className={attachmentError ? 'mb-0' : ''}
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (value?.file) {
                            const isJpgOrPng =
                              value?.file.type === 'image/png' ||
                              value?.file.type === 'image/jpg' ||
                              value?.file.type === 'image/jpeg';
                            if (!isJpgOrPng) {
                              return Promise.reject(
                                new Error('You can only upload JPG/PNG file!'),
                              );
                            }
                            const isLt5M = value?.file?.size / 1024 / 1024 < 5;
                            if (!isLt5M) {
                              return Promise.reject(
                                new Error('Image must smaller than 5 MB!'),
                              );
                            }
                            return Promise.resolve();
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Upload
                      multiple
                      fileList={[]}
                      accept=".jpg, .png"
                      onChange={handleImageUploadChange}
                      beforeUpload={() => false}
                      showUploadList={false}
                    >
                      {images?.length ? (
                        <>
                          {map(images, (image, index) => (
                            <div className="image-div">
                              <img
                                src={image?.url}
                                alt="avatar"
                                width={80}
                                height={80}
                                key={index}
                              />
                              <CloseIcon
                                height={20}
                                width={20}
                                className="close-icon pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setImages(
                                    filter(images, (record) =>
                                      editData
                                        ? record?.key !== image?.key
                                        : record?.data?.uid !==
                                        image?.data?.uid,
                                    ),
                                  );
                                }}
                              />
                            </div>
                          ))}
                        </>
                      ) : null}
                      <Button
                        type="dashed"
                        className="full-width upload-button"
                        icon={
                          images?.length ? (
                            <PlusOutlined height={20} width={20} />
                          ) : (
                            <ImageIcon height={20} width={20} />
                          )
                        }
                      >
                        Upload Image(s)
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              )}
              {isEmpty(images) && (
                <Col span={isEmpty(pdf) && 12}>
                  <Upload
                    multiple={false}
                    beforeUpload={() => false}
                    showUploadList={false}
                    accept=".pdf"
                    onChange={(info) => {
                      if (isEmpty(info?.fileList) && isEmpty(images)) {
                        setAttachmentError(true);
                      } else {
                        setAttachmentError(false);
                      }
                      const splitData = info?.file?.type?.split('/');
                      const isPdf = nth(splitData, 1);
                      if (isPdf === 'pdf') {
                        getBase64File(info?.file, (pdfUrl) =>
                          setPdf({ url: pdfUrl, data: info?.file }),
                        );
                        setAttachmentType('PDF');
                      } else {
                        message?.error('You can only upload PDF');
                      }
                    }}
                  >
                    {!isEmpty(pdf) && (
                      <div
                        className="d-flex pdf-div align-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="image">
                          <PdfIcon width={40} height={40} />
                        </div>
                        <p>{pdf?.data?.name}</p>
                      </div>
                    )}
                    <div className="d-flex">
                      {!isEmpty(pdf) && (
                        <Button
                          type="dashed"
                          className="full-width upload-button mr-12"
                          icon={<Delete height={20} width={20} />}
                          onClick={(e) => {
                            setPdf({});
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          Delete PDF
                        </Button>
                      )}
                      <Button
                        type="dashed"
                        className="full-width upload-button"
                        icon={<UploadIcon height={20} width={20} />}
                      >
                        {!isEmpty(pdf) ? 'Replace' : 'Upload'} PDF
                      </Button>
                    </div>
                  </Upload>
                </Col>
              )}
            </Row>
            {attachmentError && (
              <p className="error-message">
                Please select atleast 1 attachment!
              </p>
            )}
          </Form.Item>
          <Form.Item
            label="Quantity Deduction Variation Wise"
            className="qty-form-item"
            name={['deductQty', 'variationWise']}
            initialValue={false}
          >
            <Switch
              loading={loading}
              checked={variationWise}
              disabled={!isPackagingPresent}
              onChange={(checked) => setVariationWise(checked)}
            />
          </Form.Item>
          {!variationWise && (
            <Form.Item
              label="Quantity to Deduct (Gross Unit)"
              name={['deductQty', 'simpleDeduction']}
              rules={[
                {
                  required: true,
                  message: 'Please enter quantity to deduct!',
                  whitespace: true,
                },
              ]}
              dependencies={[['deductQty', 'variationWise']]}
              normalize={normalizeNegativeNumber}
            >
              <Input
                placeholder="Enter quantity to deduct"
                type="number"
                className="number-input"
              />
            </Form.Item>
          )}
          {variationWise && (
            <Form.List name={['deductQty', 'variationWiseDeduction']}>
              {(fields) => (
                <>
                  {fields?.map((field, index) => (
                    <Row gutter={10} key={field?.key}>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label={index === 0 && 'Variation'}
                          name={[field?.name, 'key']}
                          key={[field?.key, 'key']}
                        >
                          <Input placeholder="Enter variation" disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...field}
                          label={
                            index === 0 && 'Quantity to Deduct (Gross Unit)'
                          }
                          name={[field?.name, 'qty']}
                          key={[field?.key, 'qty']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter quantity to deduct!',
                              whitespace: true,
                            },
                          ]}
                          dependencies={[['deductQty', 'variationWise']]}
                          normalize={normalizeNegativeNumber}
                        >
                          <Input
                            placeholder="Enter quantity to deduct"
                            type="number"
                            className="number-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          )}
        </div>
        <Button
          type="primary"
          className="full-width"
          onClick={() => {
            form.submit();
          }}
          loading={buttonLoading}
          disabled={buttonLoading || fetchingCategories || loading}
        >
          {`${editData ? 'Update' : 'Add'} Product`}
        </Button>
      </Form>
    </Modal>
  );
};

export default AddEditProducts;
