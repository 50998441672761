import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd';
import { capitalize, omit, trim, upperCase } from 'lodash';
import React from 'react';
import { ROLE_KEYS } from '../../common/constants';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';

function AddEditMemberModal({
  isModalOpen,
  setIsModalOpen,
  refetch,
  selectedRecord,
  setSelectedRecord,
}) {
  const [userMutation, { loading }] = useMutation(
    selectedRecord ? UPDATE_USER : CREATE_USER,
    {
      onCompleted() {
        setSelectedRecord();
        setIsModalOpen(false);
      },
      onError: () => {},
    },
  );
  const onFinish = async (values) => {
    userMutation({
      variables: {
        input: {
          ...omit(values, selectedRecord ? ['email'] : []),
          firstName: trim(values?.firstName),
          lastName: trim(values?.lastName),
        },
        ...(selectedRecord && {
          where: { id: selectedRecord?.id },
        }),
      },
    });

    refetch();
  };

  return (
    <div id="add-edit-member-modal">
      <Modal
        centered
        title={
          <h3 className="m-0 mb-10">
            {upperCase(`${selectedRecord ? 'Edit' : 'Add New'} Member`)}
          </h3>
        }
        footer={null}
        onCancel={() => {
          setSelectedRecord();
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        width={576}
      >
        <Form
          name="basic"
          initialValues={
            selectedRecord ? { ...selectedRecord } : { role: ROLE_KEYS.ADMIN }
          }
          className="category-forms mt-16"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please input name!',
                  },
                ]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: 'Please input name!',
                  },
                ]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input email!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>
          <Form.Item
            label="Access Level"
            name="role"
            rules={[
              {
                required: true,
                message: 'Required!',
              },
            ]}
          >
            <Radio.Group>
              <Radio value={ROLE_KEYS.ADMIN}>
                {capitalize(ROLE_KEYS.ADMIN)}
              </Radio>
              <Radio value={ROLE_KEYS.STAFF}>
                {capitalize(ROLE_KEYS.STAFF)}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="full-width font-600 submit-btn"
            disabled={loading}
            loading={loading}
          >
            {selectedRecord ? `Edit` : `Add`} Member
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

export default AddEditMemberModal;
