import { gql } from '@apollo/client';

export const UPDATE_ORDER_ITEM = gql`
  mutation UpdateOrderItem(
    $where: UniqueWhereInput!
    $input: UpdateOrderItemInput!
  ) {
    updateOrderItem(where: $where, input: $input) {
      message
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateOrderStatus(
    $where: UniqueWhereInput!
    $input: UpdateOrderStatusInput!
  ) {
    updateOrderStatus(where: $where, input: $input) {
      message
    }
  }
`;

export const UPDATE_ORDER_PRICE = gql`
  mutation UpsertOrderItemPrice(
    $where: UniqueWhereInput!
    $input: UpdateOrderItemPriceInput!
  ) {
    upsertOrderItemPrice(where: $where, input: $input) {
      message
    }
  }
`;

export const GENERATE_BILL = gql`
  mutation GenerateOrderBill($where: UniqueWhereInput!) {
    generateOrderBill(where: $where) {
      message
    }
  }
`;

export const UPDATE_SHIPMENT_DETAIL = gql`
  mutation UpsertOrderShipmentDetail(
    $where: UniqueWhereInput!
    $input: UpdateOrderShipmentDetail!
  ) {
    upsertOrderShipmentDetail(where: $where, input: $input) {
      message
    }
  }
`;

export const ADD_ORDER_ITEM = gql`
  mutation AddOrderItem($input: AddOrderItemInput!) {
    addOrderItem(input: $input) {
      message
    }
  }
`;
