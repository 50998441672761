import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Mutations';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, userData, newRefreshToken) {
    initializeAuth(accessToken, userData, newRefreshToken);
  }

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    onCompleted(response) {
      const accessToken = response?.newAccessToken?.accessToken;
      const newRefreshToken = response?.newAccessToken?.refreshToken;
      const userData = response?.newAccessToken?.user;
      successCallback(accessToken, userData, newRefreshToken);
      if (state?.from && state?.from !== ROUTES.AUTHENTICATION) {
        navigate(state?.from, { replace: true });
      } else {
        navigate(ROUTES.MAIN);
      }
    },
    onError() {
      navigate(ROUTES?.LOGOUT, { replace: true });
    },
  });

  useEffect(() => {
    if (refreshToken)
      refresh({
        variables: {
          input: {
            refreshToken,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
